<template>
  <div>
    <SupplementBanner
      class="mb-4"
      :remaining-count="supplementStepStore.remainingCountOfSupplements"
    />
    <RateDialog
      :now-date="reviewPageDataStore.nowDate"
      :rate="supplementStepStore.rate"
    />
    <SupplementForm
      ref="supplementFormRef"
    />
    <AttachmentImages
      class="mb-4"
      has-document
    />
    <VBtn
      v-if="userDataStore.userData.isPhoneConfirmedByCall"
      block
      depressed
      class="mb-4"
      color="primary"
      data-qa="supplement_send_button"
      @click="handleClickSendSupplement"
    >
      <VProgressCircular
        v-if="isSomeImageSending"
        data-qa="supplement_send_button_progress"
        color="white"
        size="22"
        width="3"
        indeterminate
      />
      <template v-else>
        Отправить отзыв
      </template>
    </VBtn>
    <PolicyAndTerms />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import sendYaGoal from 'components/common/ReviewPage/functions/sendYaGoal'
import { GET_USER_EMAIL_YA_GOALS } from 'www/yandexGoals'
import readQueryMedtochkaNotification from 'medtochkaNotifications/readQueryMedtochkaNotification'
import useReviewPageDataStore from 'components/common/ReviewPage/stores/useReviewPageDataStore'
import useUserDataStore from 'components/common/ReviewPage/stores/useUserDataStore'
import useReviewImagesStore from 'components/common/ReviewPage/stores/useReviewImagesStore'
import useReviewFormDataStore from 'components/common/ReviewPage/stores/useReviewFormDataStore'
import useStarsRatingStore from 'components/common/ReviewPage/stores/useStarsRatingStore'
// eslint-disable-next-line import/extensions
import AttachmentImages from 'components/common/ReviewPage/components/common/AttachmentImages/AttachmentImages.vue'
import useSupplementStepStore from 'components/common/ReviewPage/components/common/SupplementStep/stores/useSupplementStepStore'
import sendSupplement from 'components/common/ReviewPage/components/common/SupplementStep/api/sendSupplement'
/* eslint-disable import/extensions */
import PolicyAndTerms from 'components/common/ReviewPage/components/common/PolicyAndTerms.vue'
import SupplementBanner from 'components/common/ReviewPage/components/common/SupplementStep/components/SupplementBanner.vue'
import RateDialog from 'components/common/ReviewPage/components/common/SupplementStep/components/RateDialog/RateDialog.vue'
import SupplementForm from 'components/common/ReviewPage/components/common/SupplementStep/components/SupplementForm.vue'
/* eslint-enable import/extensions */

const userDataStore = useUserDataStore()
const reviewPageDataStore = useReviewPageDataStore()
const reviewFormDataStore = useReviewFormDataStore()
const reviewImagesStore = useReviewImagesStore()
const supplementStepStore = useSupplementStepStore()
const starsRatingStore = useStarsRatingStore()

// @ts-ignore
const isSomeImageSending = computed(() => reviewImagesStore.isSomeImageSending)

const supplementFormRef = ref()

function getFormData() {
  const formData = new FormData()

  formData.append('text', supplementStepStore.supplementText)
  starsRatingStore.ratingList.forEach(({ isRateAbsent, key, value }) => {
    formData.append(
      key,
      !isRateAbsent && value
        ? String(value - 3)
        : '',
    )
  })
  reviewImagesStore.attachedImages.filter(({ isDocument }) => isDocument)
    .forEach(({ file }: { file: File }) => {
      formData.append('image_documents', file, file.name)
    })

  return formData
}

function handleSuccessSendSupplement(medtochkaUrl: string) {
  // @ts-ignore
  reviewPageDataStore.updateMedtochkaUrlOnReview(medtochkaUrl || window.MEDTOCHKA_URL)
  reviewFormDataStore.updateSubmittingReviewData({ isLoading: false, isSuccessSubmit: true })

  if (!reviewFormDataStore.isLpuInPrsAndSourceNotPrs && !userDataStore.userData.email) {
    reviewFormDataStore.updateIsVisibleReviewGetUserEmail(true)

    // @ts-ignore
    sendYaGoal(GET_USER_EMAIL_YA_GOALS.getUserEmailScreen)
  }
}

async function handleClickSendSupplement() {
  const isValidForm = await supplementFormRef.value?.validate()

  if (!isValidForm) {
    return
  }

  try {
    const { medtochka_url: medtochkaUrl } = await sendSupplement({
      reviewObjectType: reviewPageDataStore.reviewObjectType,
      rateId: supplementStepStore.rate.id,
      formData: getFormData(),
    })

    handleSuccessSendSupplement(medtochkaUrl)
    /**
     * Производит запрос на прочтение уведомления из МТ.
     *
     * Т.к. некоторые пользователи попадают в форму отзыва из СМС отправленной медточкой,
     * то при редиректе из смс в get-параметрах передаётся ID уведомления, и чтобы медточка
     * поняла что пользователь прочёл это смс, то отправляется данный запрос на соответствующий ID.
     * После этого в МТ уведомление станет серым (прочитанным), и перейдёт в раздел "Прочитанные"
     * */
    await readQueryMedtochkaNotification()
  } catch (error: any) {
    reviewFormDataStore.handleErrorsSendReviewFormData(error)
  }
}
</script>
