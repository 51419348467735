<template>
  <div
    v-if="hasPhoto || hasDocument"
    data-qa="review_images"
  >
    <div
      class="ui-text ui-text_subtitle-1 ui-kit-color-text mb-4"
      data-qa="review_images _title"
    >
      Прикрепите к отзыву
    </div>
    <DetailedInfoPanels
      :has-photo-info="hasPhoto"
      :has-document-info="hasDocument"
    />
    <FileUploader
      :activator-text="activatorText"
      activator-disabled-text="Нельзя прикрепить больше 10 файлов"
      :picker-props="{
        allowedFileExtensions,
        fileValidationRules,
        imageValidationRules,
        loadFileError: imagesErrors[FILE_ERROR_TYPES.uploadPhoto],
      }"
      :max-attached-files="imagesOptions.maxAttachedImages"
      :upload-handler="requestDocumentRecognition"
      :preview-statuses-options="attachedImagesStatuses"
      :init-attached-files="attachedImages"
      :use-load-from-camera="isMobile"
      :min-count-preview-images-on-line="3"
      :preview-image-max-width="138"
      :preview-image-max-height="138"
      preview-image-width="auto"
      preview-image-height="auto"
      min-height="120"
      use-load-from-gallery
      is-top-picker
      @file-uploader:add-file="addAttachedImage"
      @file-uploader:delete-file="deleteAttachedImage"
      @file-uploader:upload-pending="handleUploadPending"
      @file-uploader:upload-progress="handleUploadProgress"
      @file-uploader:upload-complete="handleUploadComplete"
      @file-uploader:upload-success="handleUploadSuccess"
      @file-uploader:upload-error="handleUploadError"
    />
  </div>
</template>

<script>
import { createDevNotice } from 'utils'
import { mapState, mapActions } from 'pinia'
import FileUploader from 'components/common/FileUploader/components/common/FileUploader'
import { FILE_ERROR_TYPES, REVIEW_IMAGE_STATUSES } from 'components/common/ReviewPage/constants'
import { requestDocumentRecognition } from 'components/common/ReviewPage/api'
import { useReviewPageDataStore, useReviewImagesStore } from 'components/common/ReviewPage/stores'
import DetailedInfoPanels from 'components/common/ReviewPage/components/common/AttachmentImages/components/DetailedInfoPanels'

export default {
  name: 'AttachmentImages',
  components: {
    DetailedInfoPanels,
    FileUploader,
  },
  props: {
    hasPhoto: {
      type: Boolean,
      default: false,
    },
    hasDocument: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    FILE_ERROR_TYPES,
    isMobile: window.MOBILE_VERSION,
  }),
  computed: {
    ...mapState(useReviewPageDataStore, [
      'commonData',
      'reviewObjectType',
    ]),
    ...mapState(useReviewImagesStore, [
      'attachedImages',
      'attachedImagesStatuses',
      'imagesOptions',
      'fileValidationRules',
      'imageValidationRules',
      'allowedFileExtensions',
      'imagesErrors',
    ]),
    activatorText() {
      if (this.hasPhoto && this.hasDocument) {
        return 'Прикрепить документ или фото'
      }

      if (this.hasDocument) {
        return 'Прикрепить документ'
      }

      return 'Прикрепить фото'
    },
  },
  methods: {
    ...mapActions(useReviewImagesStore, [
      'addAttachedImage',
      'deleteAttachedImage',
      'updateAttachedImage',
      'updateAttachedImagesStatus',
      'updateAttachedImagesStatuses',
    ]),
    async requestDocumentRecognition({ file }, onUploadProgress) {
      // ИИ нужен, чтобы определить тип изображения,
      // но если он заранее известен, то нет смысла отправлять запрос
      if (!this.hasDocument || !this.hasPhoto) {
        return Promise.resolve({
          recognised_as_document: this.hasDocument,
        })
      }

      const requestData = new FormData()

      requestData.append('key', this.commonData.reviewDataKey)
      requestData.append('rate_type', this.reviewObjectType)
      requestData.append('image', file, file.name)

      return requestDocumentRecognition(requestData, onUploadProgress)
    },
    handleUploadPending({ fileId }) {
      this.updateAttachedImage({ fileId, isSending: true })
      this.updateAttachedImagesStatuses({
        fileId,
        status: REVIEW_IMAGE_STATUSES.uploadPending,
      })
    },
    handleUploadProgress({ fileId, progress }) {
      this.updateAttachedImagesStatus({
        fileId,
        progressValue: progress,
      })
    },
    handleUploadComplete({ fileId }) {
      this.updateAttachedImagesStatuses({
        fileId,
        status: REVIEW_IMAGE_STATUSES.analyzingDocument,
      })
    },
    handleUploadSuccess({ fileId, data }) {
      const { recognised_as_document: isDocument } = data

      this.updateAttachedImage({
        fileId,
        isDocument: !!isDocument,
        isSending: false,
      })
      this.updateAttachedImagesStatuses({
        fileId,
        status: isDocument
          ? REVIEW_IMAGE_STATUSES.isDocument
          : REVIEW_IMAGE_STATUSES.isNotDocument,
      })
    },
    handleUploadError({ fileId, error }) {
      this.updateAttachedImage({ fileId, isSending: false })
      this.updateAttachedImagesStatuses({
        fileId,
        status: REVIEW_IMAGE_STATUSES.uploadError,
      })

      // Проверяем, что ошибка возникла не из-за отсутствия интернета у пользователя
      if (navigator.onLine) {
        createDevNotice({
          module: 'AttachmentImages',
          method: 'handleUploadError',
          description: error.message,
        })
      }
    },
  },
}
</script>
