var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "review-success-prs",
      attrs: { "data-qa": "review-success-prs" },
    },
    [
      _c(
        "VCard",
        {
          staticClass:
            "d-flex d-flex flex-column align-center ui-kit-color-text py-8",
          class: _setup.isMobile ? "px-4" : "px-6",
          attrs: { elevation: "1", rounded: "xl" },
        },
        [
          _c(
            "VIcon",
            {
              staticClass: "ui-icon_type_40 ui-kit-bg-success rounded-md",
              attrs: { color: "uiKitBgGray0" },
            },
            [_vm._v("\n      ui-icon-done-40\n    ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ui-text ui-text_h5 mt-3" }, [
            _vm._v("\n      Отзыв на проверке!\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ui-text ui-text_body-1 mt-3" }, [
            _vm._v("\n      Это займёт 1–3 дня.\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ui-text ui-text_body-1 text-center mt-3" },
            [
              _vm._v(
                "\n      Отслеживать статус отзыва и получить промокод после его публикации можно\n      в МедТочке — личном кабинете пациента.\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _setup.isMobile
            ? _c(
                "VBtn",
                {
                  staticClass: "mt-4 ui-kit-bg-bg-primary",
                  attrs: {
                    "data-qa": "review-success-prs_download-btn",
                    href: "https://4133365.redirect.appmetrica.yandex.com/?appmetrica_tracking_id=821619298169566105",
                    color: "primary",
                    depressed: "",
                    text: "",
                  },
                },
                [_vm._v("\n      Скачать МедТочку\n    ")]
              )
            : _c(
                "div",
                { staticClass: "review-success-prs__qr-wrapper" },
                [
                  _c("VImg", {
                    attrs: {
                      src: "/static/_v1/pd/qr/review/medtochka-app.png",
                      width: "182",
                      height: "182",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ui-kit-color-text-secondary ui-text ui-text_body-1 ml-4",
                    },
                    [
                      _vm._v(
                        "\n        Наведите камеру и скачайте приложение МедТочка\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "py-6", class: _setup.isMobile ? "px-4" : "px-6" },
        [
          _c(
            "div",
            {
              staticClass: "ui-text ui-text_body-1 ui-kit-color-text-secondary",
            },
            [
              _vm._v(
                "\n      Иногда клиники заказывают отзывы. Если вы стали участником создания заказного отзыва как автор,\n      поделитесь с нами, а мы вас за это вознаградим.\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(0),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mt-2" }, [
      _c(
        "a",
        {
          staticClass:
            "ui-text ui-text_body-1 ui-kit-color-text-secondary text-decoration-underline",
          attrs: { href: "/info/rate-fraud-free-rules/", target: "_blank" },
        },
        [_vm._v("Подробнее")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }