var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.BaseDialog,
    {
      attrs: {
        title: "Ваш отзыв",
        fullscreen: _setup.fullscreen,
        "confirm-btn-props": {
          text: "Понятно",
          variant: "flat",
        },
        "has-prepend-btn": "",
        "has-divider": "",
        "is-overflow-y-auto": "",
      },
      on: {
        "base-dialog:click-prepend-btn": _setup.close,
        "base-dialog:click-confirm": _setup.close,
      },
      model: {
        value: _setup.rateDialogStore.isVisible,
        callback: function ($$v) {
          _vm.$set(_setup.rateDialogStore, "isVisible", $$v)
        },
        expression: "rateDialogStore.isVisible",
      },
    },
    [
      _c(
        "div",
        {
          style: {
            /* Нужно, чтобы полностью отобразить меню с оценками отзыва*/
            minHeight: _setup.fullscreen ? "auto" : "320px",
          },
        },
        [
          _c("div", { staticClass: "d-flex align-center mb-5" }, [
            _c("div", {
              staticClass: "text-body-2 uiKitText--text mr-6",
              domProps: { textContent: _vm._s(_setup.rateCreatedDate) },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ml-auto" },
              [_c(_setup.RateAssessments)],
              1
            ),
          ]),
          _vm._v(" "),
          _vm._l(_setup.formattedSupplements, function (supplement, index) {
            return _c(
              "div",
              {
                key: `supplement-${index}`,
                staticClass:
                  "pa-4 mb-5 uiKitBgSecondary uiKitText--text rounded-sm",
              },
              [
                _c("div", {
                  staticClass: "text-subtitle-2 mb-2",
                  domProps: { textContent: _vm._s(supplement.dateText) },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "text-body-2 text-break",
                  domProps: { textContent: _vm._s(supplement.text) },
                }),
              ]
            )
          }),
          _vm._v(" "),
          _c(_setup.RateComments),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }