var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        "data-qa": "rate_assessments",
        attach: "",
        "open-on-hover": !_setup.isMobile,
        "close-on-content-click": false,
        disabled: !_setup.assessmentsTransformed.length,
        "offset-y": "",
        left: "",
        "allow-overflow": false,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ attrs, on }) {
            return [
              _c(
                "div",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "patient-avg-rate-assessment",
                      class: [
                        _setup.avgAssessmentColors,
                        {
                          "cursor-pointer":
                            _setup.assessmentsTransformed.length,
                        },
                      ],
                      attrs: { "data-qa": "rate_assessments_activator" },
                    },
                    "div",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "uiKitRating", size: "small" } },
                    [_vm._v("\n        ui-icon-rating-star-filled\n      ")]
                  ),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "text-subtitle-2 uiKitText--text ml-1",
                    attrs: { "data-qa": "rate_avg_assessment" },
                    domProps: {
                      textContent: _vm._s(_setup.normalizeAvgAssessmentText),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "ml-2",
                    attrs: { "data-qa": "rate_avg_assessment_text" },
                    domProps: { textContent: _vm._s(_setup.avgAssessmentText) },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "pa-5 uiKitBgGray0 elevation-4",
          attrs: { "data-qa": "rate_assessments_list" },
        },
        _vm._l(_setup.assessmentsTransformed, function (assessment, index) {
          return _c(
            "div",
            {
              key: assessment.name,
              class: { "mt-4": index > 0 },
              attrs: { "data-qa": "rate_assessments_item" },
            },
            [
              _c("div", {
                staticClass: "text-no-wrap",
                attrs: { "data-qa": "rate_assessments_item_name" },
                domProps: { textContent: _vm._s(assessment.name) },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _vm._l(assessment.filledStars, function (i) {
                    return _c(
                      "v-icon",
                      {
                        key: `filled-${i}`,
                        class: { "ml-1": i > 1 },
                        attrs: {
                          "data-qa": "rate_assessments_item_filled_star",
                          color: "uiKitRating",
                          small: "",
                        },
                      },
                      [
                        _vm._v(
                          "\n          ui-icon-rating-star-filled\n        "
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(assessment.outlineStars, function (i) {
                    return _c(
                      "v-icon",
                      {
                        key: `outline-${i}`,
                        staticClass: "ml-1",
                        attrs: {
                          "data-qa": "rate_assessments_item_outline_star",
                          color: "uiKitRating",
                          small: "",
                        },
                      },
                      [
                        _vm._v(
                          "\n          ui-icon-rating-star-outline\n        "
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "text-transform-capitalize ml-2",
                    class: assessment.color,
                    attrs: { "data-qa": "rate_assessments_item_status" },
                    domProps: { textContent: _vm._s(assessment.status) },
                  }),
                ],
                2
              ),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }