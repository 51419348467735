import { defineStore } from 'pinia'
import { ref } from 'vue'

const useRateDialogStore = defineStore('rateDialogStore', () => {
  const isVisible = ref(false)

  function open() {
    isVisible.value = true
  }

  return {
    isVisible,
    open,
  }
})

export default useRateDialogStore
