<template>
  <VExpansionPanels
    v-if="hasDocumentInfo || hasPhotoInfo"
    class="mb-4"
    multiple
  >
    <VExpansionPanel v-if="hasDocumentInfo">
      <VExpansionPanelHeader
        class="p-4"
        expand-icon="ui-icon-arrow-down"
      >
        <div class="mr-4">
          <div class="text-subtitle-1 uiKitText--text">
            Документы из клиники
          </div>
          <div class="text-body-1 uiKitTextSecondary--text">
            подтвердят честность отзыва
          </div>
        </div>
      </VExpansionPanelHeader>
      <VExpansionPanelContent class="mx-n2">
        <div class="d-flex align-items-center text-body-2 uiKitText--text mb-3">
          <span class="ui-icon-eye-close ui-icon-fz-medium mr-2" />
          Увидят только модераторы
        </div>
        <div class="text-body-1 uiKitText--text mb-3">
          Заключение, рецепт или другой документ с&nbsp;фамилией врача и печатью.
          Имя пациента можно прикрыть.
        </div>
        <AlertInfo
          v-if="reviewPageDataStore.commonData.isRussiaDomain"
        >
          <span class="text-body-2 uiKitText--text">
            Если врач или клиника решат оспорить отзыв в&nbsp;суде,
            мы&nbsp;защитим вас и возместим расходы.
          </span>
          <ReviewProtectionProgram>
            <template #activator="{ on, attrs }">
              <button
                type="button"
                class="primary--text link-decoration-underline"
                data-qa="review_documents_button"
                v-bind="attrs"
                v-on="on"
              >
                О программе защиты
              </button>
            </template>
          </ReviewProtectionProgram>
        </AlertInfo>
      </VExpansionPanelContent>
    </VExpansionPanel>
    <VExpansionPanel v-if="hasPhotoInfo">
      <VExpansionPanelHeader
        class="p-4"
        expand-icon="ui-icon-arrow-down"
      >
        <div class="mr-4">
          <div class="text-subtitle-1 uiKitText--text">
            Фото
          </div>
          <div class="text-body-1 uiKitTextSecondary--text">
            сделают отзыв интереснее
          </div>
        </div>
      </VExpansionPanelHeader>
      <VExpansionPanelContent class="mx-n2">
        <div class="d-flex align-items-center text-body-2 uiKitText--text mb-3">
          <span class="ui-icon-eye-open ui-icon-fz-medium mr-2" />
          Увидят все
        </div>
        <div class="text-body-1 uiKitText--text">
          Результаты лечения, до&nbsp;и&nbsp;после — всё, что&nbsp;проиллюстрирует ваш отзыв.
        </div>
      </VExpansionPanelContent>
    </VExpansionPanel>
  </VExpansionPanels>
</template>

<script lang="ts" setup>
// eslint-disable-next-line import/extensions
import AlertInfo from 'components/common/core/AlertInfo/AlertInfo.vue'
// eslint-disable-next-line import/extensions
import ReviewProtectionProgram from 'components/common/ReviewPage/components/common/ReviewProtectionProgram.vue'
import { useReviewPageDataStore } from 'components/common/ReviewPage/stores'

defineProps<{
  hasDocumentInfo: boolean,
  hasPhotoInfo: boolean,
}>()

const reviewPageDataStore = useReviewPageDataStore()
</script>
