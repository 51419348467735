<template>
  <div
    class="review-rating-stars"
    data-qa="review_rating"
  >
    <div
      v-for="rating in ratingList"
      :key="rating.key"
      data-qa="review_rating_item"
      class="review-rating-stars__item"
      :data-rating-id="rating.key"
      :data-rating-value="rating.value"
    >
      <div
        class="review-rating-stars__title ui-text ui-text_body-1"
        :class="[
          isErrorsVisible && !rating.value && ((!rating.isRateAbsentAvailable)
            || (rating.isRateAbsentAvailable && !rating.isRateAbsent))
            && !rating.hoveredValue
            ? 'ui-kit-color-text-error'
            : 'ui-kit-color-text',
        ]"
        data-qa="review_rating_title"
      >
        {{ rating.title }}
      </div>
      <VRating
        v-show="!rating.isRateAbsent"
        ref="ratingRef"
        v-model="rating.value"
        size="32"
        class="mb-3 review-rating-stars__star"
        color="uiKitRating"
        icon-label="Рейтинг {0} из {1}"
        full-icon="ui-icon-rating-star-filled"
        empty-icon="ui-icon-rating-star-outline"
        data-qa="review_rating_stars"
        :hover="!isMobile"
        :background-color="rating.value ? 'uiKitRating' : 'uiKitTextInfo'"
        @input="handleUpdateRating(rating)"
      />
      <div
        data-qa="review_rating_label"
        class="review-rating-stars__label ui-text ui-text_body-1"
        :class="`review-rating-stars__label_color_${getRatingLabelColor(rating)}`"
      >
        {{ getRatingLabel(rating) }}
      </div>

      <div
        v-if="rating.isRateAbsentAvailable"
        class="cannot-rate-button text-body-1 primary--text cursor-pointer mt-4"
        @click="handleCannotRateClick(rating)"
      >
        {{ cannotRateText(rating) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { REVIEW_RATING_LABELS } from 'components/common/ReviewPage/constants'
import { useDraftInfoStore, useStarsRatingStore, useReviewPageDataStore } from 'components/common/ReviewPage/stores'

export default {
  name: 'ReviewStarsRating',
  data: () => ({
    isMobile: window.MOBILE_VERSION,
    starsRatingSelector: '.review-rating-stars',
    starsRatingItemSelector: '.review-rating-stars__item',
    starButtonSelector: '.v-rating button.v-icon',
    destroyHoverListener: null,
  }),
  computed: {
    ...mapState(useStarsRatingStore, [
      'isErrorsVisible',
      'ratingList',
    ]),
    ...mapState(useDraftInfoStore, [
      'saveDraftDebounced',
    ]),
    ...mapState(useReviewPageDataStore, [
      'isSupplementPage',
    ]),
  },
  mounted() {
    this.createStarsHoverListener()
    this.updateStarRatingRefs(this.$refs.ratingRef)
  },
  methods: {
    ...mapActions(useStarsRatingStore, [
      'initRatingList',
      'updateRating',
      'updateStarRatingRefs',
    ]),
    getRatingLabel(rating) {
      if (rating.isRateAbsent) {
        return 'Не могу оценить'
      }

      const ratingValue = rating.hoveredValue || rating.value
      const reviewRatingLabels = REVIEW_RATING_LABELS[rating.key] || REVIEW_RATING_LABELS.default

      return reviewRatingLabels[ratingValue] || reviewRatingLabels[0]
    },
    getRatingLabelColor(rating) {
      const ratingValue = rating.hoveredValue || rating.value
      const colors = [this.isErrorsVisible && !rating.isRateAbsent ? 'error' : 'gray', 'red', 'red', 'orange', 'green', 'green']

      return colors[ratingValue]
    },
    cannotRateText(rating) {
      return rating.isRateAbsent ? 'Оценить лечение' : 'Не могу оценить'
    },
    createStarsHoverListener() {
      if (this.isMobile) {
        return
      }

      this.$refs.ratingRef.forEach((_, i) => {
        this.$watch(`$refs.ratingRef.${i}.hoverIndex`, newValue => {
          this.updateRating({
            ...this.ratingList[i],
            hoveredValue: newValue === -1 ? 0 : newValue,
          })
        })
      })
    },
    handleUpdateRating(rating) {
      this.updateRating(rating)

      if (!this.isSupplementPage) {
        this.saveDraftDebounced()
      }
    },
    handleCannotRateClick(rating) {
      this.handleUpdateRating({
        ...rating,
        isRateAbsent: !rating.isRateAbsent,
        value: 0,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.review-rating-stars {
  $cubic-bezier-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

  margin: 0 auto;
  border-radius: $border-radius-sm;
  border: 1px solid $ui-kit-bg-gray-60;

  &__title {
    margin-bottom: 12px;
    transition: $cubic-bezier-transition;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    text-align: center;
  }

  &__item + &__item {
    border-top: 1px solid $ui-kit-bg-gray-60;
  }

  &__label {
    padding: 4px 8px;
    border-radius: $border-radius-sm;
    transition: $cubic-bezier-transition;
    border: 1px solid transparent; // чтобы при появлении border при ошибке валидации не происходило "скачков"

    &_color_gray {
      color: $ui-kit-bg-gray-80;
      background-color: $ui-kit-bg-gray-40;
    }

    &_color_error {
      color: $ui-kit-text-error;
      background-color: $ui-kit-bg-gray-40;
      border-color: $ui-kit-text-error;
    }

    &_color_green {
      color: $ui-kit-success;
      background-color: $ui-kit-bg-success;
    }

    &_color_orange {
      color: $ui-kit-warning;
      background-color: $ui-kit-bg-warning;
    }

    &_color_red {
      color: $ui-kit-secondary;
      background-color: $ui-kit-bg-error;
    }
  }
}
</style>

<!-- Стили ниже позволяют избежать мерцания при наведении с одной звезды на другую -->
<style lang="scss">
.review-rating-stars {
  .v-rating .v-icon {
    border-radius: 0 !important;
  }

  .v-rating .v-ripple__container {
    border-radius: 50%;
  }
}
</style>
