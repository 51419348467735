const AssessmentBoundaries = {
  maxVeryBad: -1.2,
  maxBad: -0.4,
  maxNormal: 0.4,
  maxGood: 1.2,
}

const AssessmentTextColors = {
  default: 'uiKitText--text',
  error: 'uiKitTextError--text',
  warning: 'uiKitTextWarning--text',
  success: 'uiKitTextSuccess--text',
}

const AssessmentTexts = {
  veryBad: 'Ужасно',
  bad: 'Плохо',
  normal: 'Нормально',
  good: 'Хорошо',
  veryGood: 'Отлично',
}

const AvgAssessmentColors = {
  error: 'uiKitTextError--text uiKitBgError',
  warning: 'uiKitTextWarning--text uiKitBgWarning',
  success: 'uiKitTextSuccess--text uiKitBgSuccess',
}

const AvgAssessmentBoundaries = {
  maxError: -0.4,
  maxWarning: 0.4,
}

const StarCounts = {
  min: 0,
  max: 5,
}

export {
  AssessmentBoundaries,
  AssessmentTexts,
  AssessmentTextColors,
  AvgAssessmentBoundaries,
  AvgAssessmentColors,
  StarCounts,
}
