<template>
  <VApp
    id="review-page-app-mobile"
    data-qa="review_page"
  >
    <template v-if="commonData.isReviewOnModerateOrCanceled">
      <ReviewPageInfoScreen
        v-if="isSupplementRemainingCountEnded"
        class="px-2 pb-16 h-100"
        :title="`Вы уже дополнили свой отзыв ${supplementMaxCountText}`"
        subtitle="Больше дополнить не получится."
        confirm-button-text="Понятно"
        @screen-info:click-button-confirm="redirectToLink(reviewObjectLink)"
      />
      <ReviewPageInfoScreen
        v-else
        class="px-2 pb-16 h-100"
        title="Вы уже оставили отзыв"
        subtitle="Отслеживать статус проверки можно в личном кабинете — МедТочке."
        confirm-button-text="Посмотреть отзыв"
        close-button-text="Назад"
        @screen-info:click-button-confirm="redirectToLink(commonData.medtochkaUrlOnReview, true)"
        @screen-info:click-button-close="redirectToLink(reviewObjectLink)"
      />
    </template>
    <ReviewPageInfoScreen
      v-else-if="!commonData.canSendReview"
      class="px-2 pb-16 h-100"
      title="Отзыв нельзя оставить с рабочего аккаунта"
      confirm-button-text="Понятно"
      @screen-info:click-button-confirm="redirectToLink(reviewObjectLink)"
    />

    <ReviewGetUserEmail
      v-else-if="isVisibleReviewGetUserEmail"
    />

    <ReviewSuccessPrs v-else-if="isVisibleReviewSubmitting && isLpuInPrsAndSourceNotPrs" />

    <ReviewSubmitting
      v-else-if="isVisibleReviewSubmitting"
    />

    <ReviewPageStepper
      v-else
      class="pt-2"
    >
      <template #stepper-intro>
        <ReviewPdMobileBanner
          v-if="commonData.isPdMobile"
          class="m-2"
        />
        <div class="d-flex justify-content-between">
          <h1
            class="ui-text ui-text_h6 ui-kit-color-text mt-2 mb-6 px-2"
            data-qa="review_page_title"
          >
            {{ isSupplementPage ? 'Дополнить' : 'Оставить' }} отзыв о
            {{ reviewObjectTypes.isLpu ? 'клинике' : 'враче' }}
          </h1>
          <VBtn
            text
            icon
            depressed
            class="ml-4"
            color="uiKitIconPrimary"
            @click="handleClickClose"
          >
            <VIcon>
              ui-icon-close-not-a-circle
            </VIcon>
          </VBtn>
        </div>
        <ReviewPageInfoCard class="px-2" />
      </template>
      <template #step-1>
        <ReviewStarsRating />
      </template>
      <template #step-2>
        <SupplementStep v-if="isSupplementPage" />
        <template v-else>
          <ReviewForm class="mt-6" />
          <AttachmentImages
            has-document
            has-photo
          />
          <ReviewPhoneConfirmation class="mt-6" />
          <ReviewModalPersonalHistory />
        </template>
      </template>
    </ReviewPageStepper>

    <ReviewAuthorizationModal v-if="commonData.canSendReview" />
  </VApp>
</template>

<script>
import { mapState } from 'pinia'
import { redirectToLink, showErrorDialog } from 'components/common/ReviewPage/functions'

import ReviewForm from 'components/common/ReviewPage/components/common/ReviewForm'
import ReviewSubmitting from 'components/common/ReviewPage/components/common/ReviewSubmitting'
import ReviewPageStepper from 'components/common/ReviewPage/components/common/ReviewPageStepper'
import ReviewStarsRating from 'components/common/ReviewPage/components/common/ReviewStarsRating'
import ReviewGetUserEmail from 'components/common/ReviewPage/components/common/ReviewGetUserEmail'
import ReviewPageInfoCard from 'components/common/ReviewPage/components/common/ReviewPageInfoCard'
import ReviewPdMobileBanner from 'components/common/ReviewPage/components/common/ReviewPdMobileBanner'
import ReviewPageInfoScreen from 'components/common/ReviewPage/components/common/ReviewPageInfoScreen'
import ReviewPhoneConfirmation from 'components/common/ReviewPage/components/common/ReviewPhoneConfirmation'
import ReviewAuthorizationModal from 'components/common/ReviewPage/components/common/ReviewAuthorizationModal'
import ReviewModalPersonalHistory from 'components/common/ReviewPage/components/common/ReviewModalPersonalHistory'
import AttachmentImages from 'components/common/ReviewPage/components/common/AttachmentImages/AttachmentImages'
// eslint-disable-next-line import/extensions
import ReviewSuccessPrs from 'components/common/ReviewPage/components/common/ReviewSuccessPrs.vue'
import SupplementStep from 'components/common/ReviewPage/components/common/SupplementStep/SupplementStep'

import useSupplementStepStore from 'components/common/ReviewPage/components/common/SupplementStep/stores/useSupplementStepStore'
import getCountTextByDeclension from 'components/common/ReviewPage/functions/getCountTextByDeclension'

import {
  useSteppersStore,
  useReviewPageDataStore,
  useReviewFormDataStore,
} from 'components/common/ReviewPage/stores'

export default {
  name: 'ReviewPage',
  components: {
    SupplementStep,
    AttachmentImages,
    ReviewForm,
    ReviewSubmitting,
    ReviewPageStepper,
    ReviewStarsRating,
    ReviewGetUserEmail,
    ReviewPageInfoCard,
    ReviewPdMobileBanner,
    ReviewPageInfoScreen,
    ReviewPhoneConfirmation,
    ReviewAuthorizationModal,
    ReviewModalPersonalHistory,
    ReviewSuccessPrs,
  },
  computed: {
    ...mapState(useSteppersStore, [
      'currentStep',
    ]),
    ...mapState(useReviewPageDataStore, [
      'reviewObjectTypes',
      'reviewObjectLink',
      'commonData',
      'isSupplementPage',
    ]),
    ...mapState(useReviewFormDataStore, [
      'isVisibleReviewGetUserEmail',
      'isVisibleReviewSubmitting',
      'isLpuInPrsAndSourceNotPrs',
    ]),
    ...mapState(useSupplementStepStore, [
      'supplementOptions',
    ]),
    isSupplementRemainingCountEnded() {
      return this.isSupplementPage && (this.supplementOptions.remainingCount === 0)
    },
    supplementMaxCountText() {
      return getCountTextByDeclension(this.supplementOptions.maxCount)
    },
  },
  methods: {
    redirectToLink,
    handleClickClose() {
      showErrorDialog({
        maxWidth: 304,
        title: 'Прервать создание отзыва?',
        closeText: 'Вернуться',
        confirmText: 'Прервать',
        isDanger: true,
        beforeConfirm: () => {
          window.location.assign(this.reviewObjectLink)
        },
      })
    },
  },
}
</script>

<style lang="scss">
#review-page-app-mobile {
  .v-application--wrap {
    min-height: calc(100vh - 64px); // минус высота header
  }
}
</style>
