<template>
  <BaseDialog
    v-model="rateDialogStore.isVisible"
    title="Ваш отзыв"
    :fullscreen="fullscreen"
    :confirm-btn-props="{
      text: 'Понятно',
      variant: 'flat',
    }"
    has-prepend-btn
    has-divider
    is-overflow-y-auto
    @base-dialog:click-prepend-btn="close"
    @base-dialog:click-confirm="close"
  >
    <div
      :style="{
        /* Нужно, чтобы полностью отобразить меню с оценками отзыва*/
        minHeight: fullscreen ? 'auto' : '320px',
      }"
    >
      <div class="d-flex align-center mb-5">
        <div
          class="text-body-2 uiKitText--text mr-6"
          v-text="rateCreatedDate"
        />
        <div class="ml-auto">
          <RateAssessments />
        </div>
      </div>
      <div
        v-for="(supplement, index) in formattedSupplements"
        :key="`supplement-${index}`"
        class="pa-4 mb-5 uiKitBgSecondary uiKitText--text rounded-sm"
      >
        <div
          class="text-subtitle-2 mb-2"
          v-text="supplement.dateText"
        />
        <div
          class="text-body-2 text-break"
          v-text="supplement.text"
        />
      </div>
      <RateComments />
    </div>
  </BaseDialog>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import BaseDialog from 'components/common/core/Base/BaseDialog/BaseDialog.vue' // eslint-disable-line import/extensions
import useRateDialogStore from 'components/common/ReviewPage/components/common/SupplementStep/components/RateDialog/stores/useRateDialogStore'
// eslint-disable-next-line import/extensions
import RateComments from 'components/common/ReviewPage/components/common/SupplementStep/components/RateDialog/components/RateComments/RateComments.vue'
// eslint-disable-next-line import/extensions
import RateAssessments from 'components/common/ReviewPage/components/common/SupplementStep/components/RateDialog/components/RateAssessments/RateAssessments.vue'
import { Rate } from 'components/common/ReviewPage/components/common/SupplementStep/types'

const props = defineProps<{
  rate: Rate,
  nowDate: Date | null,
}>()
const rateDialogStore = useRateDialogStore()

const fullscreen = computed(() => window.MOBILE_VERSION)
const formattedSupplements = computed(() => (
  props.rate.supplements.map(({ createdDateString, text }) => {
    const createdDate = new Date(createdDateString)

    return {
      milliseconds: createdDate.getTime(),
      dateText: `Дополнение от ${format(createdDate, 'd MMMM', { locale: ru })}`,
      text,
    }
  })
    .sort((a, b) => b.milliseconds - a.milliseconds)
))
const rateCreatedDate = computed(() => {
  const dateString = props.rate.createdDateString

  if (!dateString) {
    return ''
  }

  const rateDate = new Date(dateString)
  const formatString = props.nowDate && rateDate.getFullYear() === props.nowDate.getFullYear()
    ? 'd MMMM в H:mm'
    : 'd MMMM y в H:mm'

  return format(rateDate, formatString, { locale: ru })
})

function close() {
  rateDialogStore.isVisible = false
}
</script>
