import { defineStore } from 'pinia'
import UserStorage from 'modules/UserStorage'
import useUserDataStore from 'components/common/ReviewPage/stores/useUserDataStore'
import useReviewImagesStore from 'components/common/ReviewPage/stores/useReviewImagesStore'
import useReviewPageDataStore from 'components/common/ReviewPage/stores/useReviewPageDataStore'
import sendYaGoal from 'components/common/ReviewPage/functions/sendYaGoal'
import showErrorDialog from 'components/common/ReviewPage/functions/showErrorDialog'
// eslint-disable-next-line import/no-cycle
import buildRequestData from 'components/common/ReviewPage/functions/buildRequestData'
import scrollToInvalidField from 'components/common/ReviewPage/functions/scrollToInvalidField'
import { createDevLog, scrollToElement, showCommonErrorDialog } from 'utils'
import { readQueryMedtochkaNotification } from 'medtochkaNotifications'
import { needsPhoneReconfirm, reconfirmPhone } from 'blocksMixins/auth'
import { requestNewReview, checkPersonalHistory } from 'components/common/ReviewPage/api'
import { PAGE_NEWRATE_YANDEX_GOALS, GET_USER_EMAIL_YA_GOALS } from 'yandexGoals'
import {
  SELECTED_DATE,
  USER_ACTION_AFTER_AUTH,
  PATIENT_TYPE_NAME,
  PRELOADER_DELAY_MS,
  PATIENTS_FROM_APPOINTMENT,
  OTHER_LPU_NAME_AND_ADDRESS_ID,
  MAX_FIELD_OTHER_PATIENT_LENGTH,
  MAX_FIELD_OTHER_LPU_NAME_LENGTH,
} from 'components/common/ReviewPage/constants'
import {
  STORAGE_KEY as DRAFT_REMINDER_STORAGE_KEY,
} from 'components/common/DraftReminder/constants'
import useSupplementStepStore from 'components/common/ReviewPage/components/common/SupplementStep/stores/useSupplementStepStore'

/**
 * Содержит данные формы и всех её полей
 * */

const useReviewFormDataStore = defineStore('reviewFormDataStore', {
  state: () => ({
    inputData: {
      selectedPatient: '',
      otherPatientFromReview: '',
      selectedLpuAddressId: null,
      otherLpuNameAndAddress: '',
      selectedDate: '',
      comment: '',
      commentPlus: '',
      commentMinus: '',
    },
    isValidForm: false,
    reviewFormRef: null,
    reviewFormFieldRefs: null,
    reviewFormDocumentsRef: null,
    behavioralFactorsInstance: null,
    patientsFromAppointment: PATIENTS_FROM_APPOINTMENT,
    minSelectedDate: SELECTED_DATE.min,
    maxSelectedDate: SELECTED_DATE.max,
    isBeenValidationAttempted: false,
    isTextareaFocused: false,
    isAttachedDocument: false,
    isVisibleReviewGetUserEmail: false,
    fastValidationData: {
      isFailedRequest: false,
      isFoundBadWords: false,
      isUserAgreeNonBadWords: false,
    },
    submittingReviewData: {
      isLoading: false,
      isSuccessSubmit: false,
    },
    personalHistoryData: {
      isLoading: false,
      isVisibleModal: false,
      isPersonalHistory: null,
    },
    otherLpuAddressItem: {
      id: OTHER_LPU_NAME_AND_ADDRESS_ID,
      lpuName: 'Другая клиника',
    },
  }),
  getters: {
    itemsLpuAddresses() {
      const { lpuData, doctorData, reviewObjectTypes } = useReviewPageDataStore()
      const lpuAddresses = reviewObjectTypes.isLpu
        ? lpuData.lpuAddresses
        : doctorData.lpuAddresses

      if (!lpuAddresses.length) {
        return []
      }

      if (reviewObjectTypes.isLpu) {
        return lpuAddresses
      }

      return [...lpuAddresses, this.otherLpuAddressItem]
    },
    selectedLpuAddress() {
      const { lpuData, doctorData, reviewObjectTypes } = useReviewPageDataStore()
      const lpuAddresses = reviewObjectTypes.isLpu ? lpuData.lpuAddresses : doctorData.lpuAddresses

      if (!lpuAddresses.length) {
        return null
      }

      return lpuAddresses.find(lpuAddress => this.inputData.selectedLpuAddressId === lpuAddress.id)
    },
    isSelectedLpuAddressInPrs() {
      const { lpuData, reviewObjectTypes } = useReviewPageDataStore()

      return reviewObjectTypes.isLpu ? lpuData.isInPrs : !!this.selectedLpuAddress?.isInPrs
    },
    isLpuInPrsAndSourceNotPrs() {
      const reviewPageDataStore = useReviewPageDataStore()

      return this.isSelectedLpuAddressInPrs && !reviewPageDataStore.commonData.isReviewSourcePrs
    },
    isSelectedLpuAddressOther() {
      return this.inputData.selectedLpuAddressId === this.otherLpuAddressItem.id
    },
    isSelectedPatientAnotherMan() {
      return this.inputData.selectedPatient === PATIENT_TYPE_NAME.anotherMan
    },
    isSelectedPatientPersonally() {
      return this.inputData.selectedPatient === PATIENT_TYPE_NAME.personally
    },
    isVisibleAlertLengthNeed() {
      return this.isBeenValidationAttempted && !!this.reviewTextLengthNeed
    },
    isVisibleReviewSubmitting() {
      return this.submittingReviewData.isLoading
        || this.submittingReviewData.isSuccessSubmit
    },
    isReviewFieldTooMuchLong() {
      return this.inputData.comment.length > window.MAX_REVIEW_FIELD_LENGTH
        || this.inputData.commentPlus.length > window.MAX_REVIEW_FIELD_LENGTH
        || this.inputData.commentMinus.length > window.MAX_REVIEW_FIELD_LENGTH
    },
    reviewTextLength() {
      return this.inputData.comment.length
        + this.inputData.commentPlus.length
        + this.inputData.commentMinus.length
    },
    reviewTextLengthNeed() {
      if (this.reviewTextLength >= window.MIN_REVIEW_FIELDS_TOTAL_LENGTH) {
        return 0
      }

      return window.MIN_REVIEW_FIELDS_TOTAL_LENGTH - this.reviewTextLength
    },
    rulesValidationComments() {
      return [
        v => !/[A-ZА-ЯЁ]{6}/.test(v) || 'Пожалуйста, не пишите слова заглавными буквами',
        v => v.length <= window.MAX_REVIEW_FIELD_LENGTH || `Максимум ${window.MAX_REVIEW_FIELD_LENGTH.toLocaleString('ru-RU')} символов`,
        !this.isBeenValidationAttempted || !this.reviewTextLengthNeed,
      ]
    },
    rulesValidationSelectedPatient() {
      return this.isBeenValidationAttempted
        ? [v => !!v || 'Укажите, кто был на приёме']
        : []
    },
    rulesValidationSelectedLpuAddressId() {
      return this.isBeenValidationAttempted
        ? [v => !!v || 'Укажите клинику']
        : []
    },
    rulesValidationSelectedDate() {
      return this.isBeenValidationAttempted
        ? [v => !!v || 'Укажите дату посещения']
        : []
    },
    rulesValidationOtherLpuNameAndAddress() {
      const require = this.isBeenValidationAttempted && this.isSelectedLpuAddressOther
        ? v => !!v || 'Укажите название и адрес'
        : true
      const length = this.isSelectedLpuAddressOther
        ? v => v.length <= MAX_FIELD_OTHER_LPU_NAME_LENGTH || `Максимум ${MAX_FIELD_OTHER_LPU_NAME_LENGTH} символов`
        : true

      return [require, length]
    },
    rulesValidationOtherPatientFromReview() {
      const require = this.isBeenValidationAttempted && this.isSelectedPatientAnotherMan
        ? v => !!v || 'Укажите, кем вам приходится человек из отзыва'
        : true
      const length = this.isSelectedPatientAnotherMan
        ? v => v.length <= MAX_FIELD_OTHER_PATIENT_LENGTH || `Максимум ${MAX_FIELD_OTHER_PATIENT_LENGTH} символов`
        : true

      return [require, length]
    },
  },
  actions: {
    updateReviewFormRef(payload) {
      this.reviewFormRef = payload
    },
    updateReviewFormFieldRefs(payload) {
      this.reviewFormFieldRefs = payload
    },
    updateReviewFormDocumentsRef(payload) {
      this.reviewFormDocumentsRef = payload
    },
    updateBehavioralFactorsInstance(payload) {
      this.behavioralFactorsInstance = payload
    },
    updateComment(payload) {
      this.inputData.comment = payload
    },
    updateCommentPlus(payload) {
      this.inputData.commentPlus = payload
    },
    updateCommentMinus(payload) {
      this.inputData.commentMinus = payload
    },
    updateSelectedDate(payload) {
      this.inputData.selectedDate = payload
    },
    updateSelectedPatient(payload) {
      this.inputData.selectedPatient = payload
    },
    updateSelectedLpuAddressId(payload) {
      this.inputData.selectedLpuAddressId = payload
    },
    updateOtherPatientFromReview(payload) {
      this.inputData.otherPatientFromReview = payload
    },
    updateOtherLpuNameAndAddress(payload) {
      this.inputData.otherLpuNameAndAddress = payload
    },
    updateIsBeenValidationAttempted(payload) {
      this.isBeenValidationAttempted = payload
    },
    updateIsTextareaFocused(payload) {
      this.isTextareaFocused = payload
    },
    updateIsAttachedDocument(payload) {
      this.isAttachedDocument = payload
    },
    updateIsVisibleReviewGetUserEmail(payload) {
      this.isVisibleReviewGetUserEmail = payload
    },
    updateIsValidForm(payload) {
      this.isValidForm = payload
    },
    updateFastValidationData(payload) {
      this.fastValidationData = {
        ...this.fastValidationData,
        ...payload,
      }
    },
    updatePersonalHistoryData(payload) {
      this.personalHistoryData = {
        ...this.personalHistoryData,
        ...payload,
      }
    },
    updateSubmittingReviewData(payload) {
      this.submittingReviewData = {
        ...this.submittingReviewData,
        ...payload,
      }
    },

    async validateForm() {
      await this.updateIsBeenValidationAttempted(true)

      // Если форма не валидна, скроллим к первому невалидному полю
      if (!this.reviewFormRef.validate() || this.fastValidationData.isFoundBadWords) {
        scrollToInvalidField({
          reviewFormFieldRefs: this.reviewFormFieldRefs,
          fastValidationData: this.fastValidationData,
        })

        return
      }

      // Если форма была ранее отвалидированна, то валидация ниже не производится
      if (this.isValidForm) {
        return
      }

      this.updatePersonalHistoryData({ isLoading: true })

      // Производится быстрая валидация отзыва
      if (!this.fastValidationData.isUserAgreeNonBadWords) {
        await this.handleRequestFastReviewValidation()

        if (this.fastValidationData.isFailedRequest) {
          return
        }
      }

      // Производится проверка личной истории с помощью ИИ
      await this.handleRequestCheckPersonalHistory()

      if (!this.personalHistoryData.isPersonalHistory) {
        return
      }

      this.updateIsValidForm(true)
    },
    async sendReviewFormData() {
      const userDataStore = useUserDataStore()
      const reviewImagesStore = useReviewImagesStore()
      const reviewPageDataStore = useReviewPageDataStore()

      if (reviewImagesStore.isSomeImageSending) {
        return
      }

      const isAttachedDocument = reviewImagesStore.attachedImages.some(file => file.isDocument)

      this.updateIsAttachedDocument(isAttachedDocument)

      const requestData = buildRequestData({
        asRequestForReview: true,
      })

      this.updateSubmittingReviewData({ isLoading: true })

      setTimeout(() => {
        scrollToElement(reviewPageDataStore.reviewPageNode)
      })

      if (await needsPhoneReconfirm()) {
        await reconfirmPhone()

        this.updateSubmittingReviewData({ isLoading: false })

        showErrorDialog({
          title: 'Ошибка авторизации',
          text: 'Обновите страницу и попробуйте ещё раз.',
          closeText: '',
          confirmText: 'Обновить',
          beforeConfirm: () => window.location.reload(),
        })

        return
      }

      try {
        this.hookBeforeSendForm()

        const { data: response } = await requestNewReview(requestData, {
          reviewObjectId: reviewPageDataStore.reviewObjectId,
          reviewObjectType: reviewPageDataStore.reviewObjectType,
        })

        if (response?.detail?.toLowerCase?.() !== 'ok') { // Если соединение оборвется - будет статус код 200, но при этом отсутствовать response
          showErrorDialog({
            title: 'Не получилось отправить отзыв',
            text: 'Мы сохранили текст отзыва, обновите страницу и отправьте отзыв снова.',
            closeText: '',
            confirmText: 'Обновить',
            beforeConfirm: () => window.location.reload(),
          })

          this.updateSubmittingReviewData({ isLoading: false, isSuccessSubmit: false })

          return
        }

        // eslint-disable-next-line require-atomic-updates
        reviewPageDataStore.updateMedtochkaUrlOnReview(response.medtochka_url || window.MEDTOCHKA_URL)

        this.updateSubmittingReviewData({ isLoading: false, isSuccessSubmit: true })

        if (!this.isLpuInPrsAndSourceNotPrs && !userDataStore.userData.email) {
          this.updateIsVisibleReviewGetUserEmail(true)

          sendYaGoal(GET_USER_EMAIL_YA_GOALS.getUserEmailScreen)
        }

        this.hookAfterSendForm()
      } catch (e) {
        this.updateSubmittingReviewData({ isLoading: false })

        this.handleErrorsSendReviewFormData(e)
      }
    },
    hookBeforeSendForm() {
      const { reviewObjectTypes } = useReviewPageDataStore()

      sendYaGoal(PAGE_NEWRATE_YANDEX_GOALS[reviewObjectTypes.isLpu ? 'commonSendFormLpu' : 'commonSendFormDoctor'])

      const { attachedImages } = useReviewImagesStore()
      const isAttachedDocument = attachedImages.some(file => file.isDocument)
      const isAttachedPhoto = attachedImages.some(file => !file.isDocument)

      if (isAttachedDocument) {
        sendYaGoal(PAGE_NEWRATE_YANDEX_GOALS[reviewObjectTypes.isLpu ? 'commonAttachDocumentsLpu' : 'commonAttachDocumentsDoctor'])
      }

      if (isAttachedPhoto) {
        sendYaGoal(PAGE_NEWRATE_YANDEX_GOALS[reviewObjectTypes.isLpu ? 'commonAttachPhotosLpu' : 'commonAttachPhotosDoctor'])
      }
    },
    async hookAfterSendForm() {
      const { reviewObjectTypes } = useReviewPageDataStore()

      sendYaGoal(PAGE_NEWRATE_YANDEX_GOALS[reviewObjectTypes.isLpu ? 'commonSuccessMessageLpu' : 'commonSuccessMessageDoctor'])

      UserStorage.remove(DRAFT_REMINDER_STORAGE_KEY)
      this.behavioralFactorsInstance.send()

      /**
       * Производит запрос на прочтение уведомления из МТ.
       *
       * Т.к. некоторые пользователи попадают в форму отзыва из СМС отправленной медточкой,
       * то при редиректе из смс в get-параметрах передаётся ID уведомления, и чтобы медточка
       * поняла что пользователь прочёл это смс, то отправляется данный запрос на соответствующий ID.
       * После этого в МТ уведомление станет серым (прочитанным), и перейдёт в раздел "Прочитанные"
       * */
      await readQueryMedtochkaNotification()
    },
    async handleRequestCheckExistReview() {
      const requestData = buildRequestData()

      try {
        const reviewPageDataStore = useReviewPageDataStore()

        await requestNewReview(requestData, {
          reviewObjectId: reviewPageDataStore.reviewObjectId,
          reviewObjectType: reviewPageDataStore.reviewObjectType,
          isOnlyCheckExistReview: true,
        })
      } catch (e) {
        this.handleErrorsSendReviewFormData(e)
      }
    },
    async handleRequestFastReviewValidation() {
      const requestData = buildRequestData()

      try {
        this.updatePersonalHistoryData({ isLoading: true })
        this.updateFastValidationData({ isFailedRequest: false })

        const reviewPageDataStore = useReviewPageDataStore()

        await requestNewReview(requestData, {
          reviewObjectId: reviewPageDataStore.reviewObjectId,
          reviewObjectType: reviewPageDataStore.reviewObjectType,
          isOnlyValidate: true,
        })
      } catch (e) {
        this.updatePersonalHistoryData({ isLoading: false })
        this.updateFastValidationData({ isFailedRequest: true })

        const { data: errorResponse } = e.response || {}

        if (errorResponse?.data?.code === 'bad_words_in_rate') {
          await this.updateFastValidationData({ isFoundBadWords: true })
          this.reviewFormRef.validate()
          scrollToInvalidField({
            reviewFormFieldRefs: this.reviewFormFieldRefs,
            fastValidationData: this.fastValidationData,
          })

          return
        }

        this.handleErrorsSendReviewFormData(e)
      }
    },
    handleErrorsSendReviewFormData(e) {
      const { data: errorResponse, status } = e.response || {}
      const errorCode = errorResponse?.data?.code
      const existRateCodes = ['exist_rate_on_moderation', 'exist_rate_on_correct']
      const errorCodes = window.FEATURE_FLAGS.pd_add_supplement_review
        ? [...existRateCodes, 'exist_rate_supplement_on_moderation', 'exist_rate_supplement_max_count']
        : existRateCodes

      if (errorCode && errorCodes.includes(errorCode)) {
        const reviewPageDataStore = useReviewPageDataStore()

        if (window.FEATURE_FLAGS.pd_add_supplement_review && errorCode === 'exist_rate_supplement_max_count') {
          const supplementStepStore = useSupplementStepStore()

          supplementStepStore.supplementOptions.remainingCount = 0
        }

        reviewPageDataStore.updateIsReviewOnModerateOrCanceled(true)
        reviewPageDataStore.updateMedtochkaUrlOnReview(errorResponse.data.additional_info.medtochka_url)

        createDevLog({
          module: 'storeReviewFormData',
          title: 'sendReviewFormData',
          message: !window.FEATURE_FLAGS.pd_add_supplement_review || existRateCodes.includes(errorCode)
            ? `Найден существующий отзыв. Код: ${errorCode}`
            : `Найдено существующее дополнение. Код: ${errorCode}`,
        })

        return
      }

      if (status === 415) {
        showErrorDialog({
          title: 'Неподходящий формат',
          text: 'Подойдёт JPEG или PNG.',
        })

        return
      }

      if (status === 403) {
        const userDataStore = useUserDataStore()

        showErrorDialog({
          title: 'Войдите в свой профиль, чтобы продолжить',
          closeText: 'Войти',
          persistent: true,
          beforeClose: () => {
            userDataStore.resetUserAuthorization(USER_ACTION_AFTER_AUTH.sendReview)
          },
        })

        return
      }

      if (status === 499 || status === 0 || !window.navigator.onLine || e.message === 'Network Error') {
        showErrorDialog({
          title: 'Нет соединения с интернетом',
          text: 'Проверьте соединение и попробуйте снова.',
        })

        return
      }

      showCommonErrorDialog(e, {
        sentryModule: 'storeReviewFormData',
        sentryMethod: 'sendReviewFormData',
      })
    },
    async handleRequestCheckPersonalHistory() {
      try {
        const reviewPageDataStore = useReviewPageDataStore()

        const { isDoctor } = reviewPageDataStore.reviewObjectTypes
        const { townId } = isDoctor
          ? reviewPageDataStore.doctorData
          : reviewPageDataStore.lpuData

        if (!window.isEnabledPersonalHistoryService) {
          this.updatePersonalHistoryData({
            isLoading: false,
            isVisibleModal: false,
            isPersonalHistory: true,
          })

          return
        }

        const startRequestDate = performance.now()
        const { isPersonalHistory } = await checkPersonalHistory({
          townId,
          comment: this.inputData.comment,
          commentPlus: this.inputData.commentPlus,
          commentMinus: this.inputData.commentMinus,
        })
        const endRequestDate = performance.now()
        const diffRequestDate = endRequestDate - startRequestDate
        const $this = this

        // Производится ожидание 4сек перед скрытием прелоадера
        await new Promise(resolve => {
          setTimeout(() => {
            $this.updatePersonalHistoryData({
              isLoading: false,
              isVisibleModal: !isPersonalHistory,
              isPersonalHistory,
            })

            resolve()
          }, PRELOADER_DELAY_MS - diffRequestDate)
        })
      } catch (e) {
        this.updatePersonalHistoryData({
          isLoading: false,
          isVisibleModal: false,
          isPersonalHistory: false,
        })

        this.handleErrorsCheckPersonalHistory(e)
      }
    },
    handleErrorsCheckPersonalHistory() {
      if (!window.navigator.onLine) {
        showErrorDialog({
          title: 'Нет соединения с интернетом',
          text: 'Проверьте соединение и попробуйте снова.',
        })

        return
      }

      showErrorDialog({
        title: 'У нас что-то сломалось',
        text: 'Попробуйте ещё раз.',
      })
    },
  },
})

export default useReviewFormDataStore
