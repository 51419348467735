import type { ModalType } from 'components/common/core/Base/BaseDialog/types'

export const MODAL_TYPES: { [key in ModalType]: key } = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
}

export const MODAL_WIDTHS: Record<ModalType, number> = {
  sm: 304,
  md: 360,
  lg: 456,
  xl: 650,
}
