import styleVars from 'www/themes/doctors/common/variables-export.module.scss'

export const MOUNT_ID_REVIEW_PAGE = 'review-page-mount'

export const PRELOADER_DELAY_MS = 2000

export const DRAFT_SAVING_DEBOUNCE_DELAY_MS = 3000

export const MAX_FIELD_OTHER_PATIENT_LENGTH = 56

export const MAX_FIELD_OTHER_LPU_NAME_LENGTH = 120

export const OTHER_LPU_NAME_AND_ADDRESS_ID = 'other'

export const SELECTED_DATE = {
  min: new Date('1950').toISOString().slice(0, 10), // 1950
  max: new Date().toISOString().slice(0, 10), // now
}

export const REVIEW_RATING_LABELS = {
  default: {
    0: 'Нет оценки',
    1: 'Ужасно',
    2: 'Плохо',
    3: 'Нормально',
    4: 'Хорошо',
    5: 'Отлично',
  },
  recommend: {
    0: 'Нет оценки',
    1: 'Никогда',
    2: 'Нет',
    3: 'Возможно',
    4: 'Да',
    5: 'Обязательно',
  },
}

export const REVIEW_RATING_FEATURE_LISTS = {
  lpu: [
    'building', 'equipment', 'staff', 'comfort', 'wait',
  ],
  lpuHospital: [
    'building', 'equipment', 'staff', 'comfort', 'food',
  ],
  doctor: [
    'inspection', 'efficiency', 'friendly', 'informative', 'recommend',
  ],
}

export const REVIEW_RATING_FEATURES = {
  // lpu
  food: {
    key: 'food',
    title: 'Качество питания',
    isRateAbsentAvailable: false,
  },
  wait: {
    key: 'food',
    title: 'Время ожидания',
    isRateAbsentAvailable: false,
  },
  staff: {
    key: 'medstaff',
    title: 'Отношение медперсонала',
    isRateAbsentAvailable: false,
  },
  building: {
    key: 'building',
    title: 'Здание и помещения',
    isRateAbsentAvailable: false,
  },
  equipment: {
    key: 'equipment',
    title: 'Оборудование и медикаменты',
    isRateAbsentAvailable: false,
  },
  comfort: {
    key: 'leisure',
    title: 'Комфорт пребывания',
    isRateAbsentAvailable: false,
  },

  // doctor
  friendly: {
    key: 'friendliness',
    title: 'Отношение к пациенту',
    isRateAbsentAvailable: false,
  },
  recommend: {
    key: 'recommend',
    title: 'Посоветуете ли Вы врача?',
    isRateAbsentAvailable: false,
  },
  inspection: {
    key: 'osmotr',
    title: 'Тщательность обследования',
    isRateAbsentAvailable: false,
  },
  efficiency: {
    key: 'efficiency',
    title: 'Эффективность лечения',
    isRateAbsentAvailable: true,
  },
  informative: {
    key: 'informativity',
    title: 'Информирование пациента',
    isRateAbsentAvailable: false,
  },
}

export const PATIENT_TYPE_NAME = {
  personally: 'me',
  child: 'child',
  grandchild: 'grandchild',
  spouse: 'spouse',
  myParent: 'my_parent',
  spousesParent: 'spouses_parent',
  sibling: 'sibling',
  grandparent: 'grandparent',
  parentSibling: 'parent_sibling',
  anotherMan: 'another',
}

export const PATIENT_TYPE_TITLES = {
  [PATIENT_TYPE_NAME.personally]: 'Я',
  [PATIENT_TYPE_NAME.child]: 'Сын или дочь',
  [PATIENT_TYPE_NAME.grandchild]: 'Внук или внучка',
  [PATIENT_TYPE_NAME.spouse]: 'Муж или жена',
  [PATIENT_TYPE_NAME.myParent]: 'Мать или отец',
  [PATIENT_TYPE_NAME.spousesParent]: 'Мать или отец супруга(и)',
  [PATIENT_TYPE_NAME.sibling]: 'Брат или сестра',
  [PATIENT_TYPE_NAME.grandparent]: 'Дедушка или бабушка',
  [PATIENT_TYPE_NAME.parentSibling]: 'Родной дядя или тётя',
  [PATIENT_TYPE_NAME.anotherMan]: 'Другой человек',
}

export const PATIENTS_FROM_APPOINTMENT = [
  {
    value: PATIENT_TYPE_NAME.personally,
    title: PATIENT_TYPE_TITLES[PATIENT_TYPE_NAME.personally],
  },
  {
    value: PATIENT_TYPE_NAME.child,
    title: PATIENT_TYPE_TITLES[PATIENT_TYPE_NAME.child],
  },
  {
    value: PATIENT_TYPE_NAME.grandchild,
    title: PATIENT_TYPE_TITLES[PATIENT_TYPE_NAME.grandchild],
  },
  {
    value: PATIENT_TYPE_NAME.spouse,
    title: PATIENT_TYPE_TITLES[PATIENT_TYPE_NAME.spouse],
  },
  {
    value: PATIENT_TYPE_NAME.myParent,
    title: PATIENT_TYPE_TITLES[PATIENT_TYPE_NAME.myParent],
  },
  {
    value: PATIENT_TYPE_NAME.spousesParent,
    title: PATIENT_TYPE_TITLES[PATIENT_TYPE_NAME.spousesParent],
  },
  {
    value: PATIENT_TYPE_NAME.sibling,
    title: PATIENT_TYPE_TITLES[PATIENT_TYPE_NAME.sibling],
  },
  {
    value: PATIENT_TYPE_NAME.grandparent,
    title: PATIENT_TYPE_TITLES[PATIENT_TYPE_NAME.grandparent],
  },
  {
    value: PATIENT_TYPE_NAME.parentSibling,
    title: PATIENT_TYPE_TITLES[PATIENT_TYPE_NAME.parentSibling],
  },
  {
    value: PATIENT_TYPE_NAME.anotherMan,
    title: PATIENT_TYPE_TITLES[PATIENT_TYPE_NAME.anotherMan],
  },
]

export const TEXTAREA_NAMES = {
  comment: 'comment',
  commentPlus: 'comment_plus',
  commentMinus: 'comment_minus',
}

export const FILE_VALIDATION_TYPES = {
  fileType: 'fileType',
  maxOverallFilesSize: 'maxOverallFilesSize',
  maxPhotoSize: 'maxPhotoSize',
  minPhotoResolution: 'minPhotoResolution',
  maxPhotoPixels: 'maxPhotoPixels',
}

export const FILE_ERROR_TYPES = {
  ...FILE_VALIDATION_TYPES,
  uploadPhoto: 'uploadPhoto',
}

export const UPLOAD_IMAGES_RULE_TYPES = {
  file: [
    FILE_VALIDATION_TYPES.fileType,
    FILE_VALIDATION_TYPES.maxPhotoSize,
    FILE_VALIDATION_TYPES.maxOverallFilesSize,
  ],
  image: [
    FILE_VALIDATION_TYPES.minPhotoResolution,
    FILE_VALIDATION_TYPES.maxPhotoPixels,
  ],
}

export const FEEDBACK_PROGRESS_STATUSES = {
  canSend: 'canSend',
  detailed: 'detailed',
  ideal: 'ideal',
}

export const FEEDBACK_PROGRESS_STATUS_NAMES = {
  [FEEDBACK_PROGRESS_STATUSES.canSend]: 'Можно отправить',
  [FEEDBACK_PROGRESS_STATUSES.detailed]: 'Подробный',
  [FEEDBACK_PROGRESS_STATUSES.ideal]: 'Идеальный',
}

export const FEEDBACK_PROGRESS_STEPS = [
  {
    percentRange: [25, 30],
    symbolsRange: [0, 50],
    emoji: {
      src: '/static/_v1/pd/icons/emoji/upside-down-face.png',
      alt: 'Перевернутое лицо',
    },
    colorProgress: styleVars.uiKitError,
    activeStatuses: [],
  },
  {
    percentRange: [30, 55],
    symbolsRange: [50, window.MIN_REVIEW_FIELDS_TOTAL_LENGTH],
    emoji: {
      src: '/static/_v1/pd/icons/emoji/face-with-hand-over-mouth.png',
      alt: 'Лицо с рукой за ртом',
    },
    colorProgress: styleVars.uiKitError,
    activeStatuses: [],
  },
  {
    percentRange: [55, 70],
    symbolsRange: [window.MIN_REVIEW_FIELDS_TOTAL_LENGTH, 359],
    emoji: {
      src: '/static/_v1/pd/icons/emoji/neutral-face.png',
      alt: 'Нейтральное лицо',
    },
    colorProgress: styleVars.uiKitRating,
    activeStatuses: [
      FEEDBACK_PROGRESS_STATUSES.canSend,
    ],
  },
  {
    percentRange: [70, 85],
    symbolsRange: [359, 500],
    emoji: {
      src: '/static/_v1/pd/icons/emoji/slightly-smiling-face.png',
      alt: 'Слегка улыбающееся лицо',
    },
    colorProgress: styleVars.uiKitSuccess,
    activeStatuses: [
      FEEDBACK_PROGRESS_STATUSES.canSend,
    ],
  },
  {
    percentRange: [85, 100],
    symbolsRange: [500, 860],
    emoji: {
      src: '/static/_v1/pd/icons/emoji/grinning-face-with-big-eyes.png',
      alt: 'Ухмыляющееся лицо с большими глазами',
    },
    colorProgress: styleVars.uiKitSuccess,
    activeStatuses: [
      FEEDBACK_PROGRESS_STATUSES.canSend,
      FEEDBACK_PROGRESS_STATUSES.detailed,
    ],
  },
  {
    percentRange: [100, Infinity],
    symbolsRange: [860, Infinity],
    emoji: {
      src: '/static/_v1/pd/icons/emoji/star-struck.png',
      alt: 'Пораженный звездой',
    },
    colorProgress: styleVars.uiKitSuccess,
    activeStatuses: [
      FEEDBACK_PROGRESS_STATUSES.canSend,
      FEEDBACK_PROGRESS_STATUSES.detailed,
      FEEDBACK_PROGRESS_STATUSES.ideal,
    ],
  },
]

export const REVIEW_OBJECT_TYPES = {
  doctor: 'doctor',
  lpu: 'lpu',
}

export const REVIEW_IMAGE_STATUSES = {
  uploadPending: {
    description: 'Загрузка',
    descriptionColor: 'uiKitTextSecondary',
    iconColor: 'primary',
    iconWidth: 32,
    iconHeight: 32,
    iconSize: 24,
    isProgress: true,
    progressValue: 0,
    progressWidth: 2,
  },
  uploadError: {
    description: 'Не загружен',
    descriptionColor: 'uiKitError',
    icon: 'ui-icon-reset',
    iconColor: 'uiKitTextInfo',
    iconClasses: 'cursor-pointer',
    iconWidth: 32,
    iconHeight: 32,
    iconSize: 24,
  },
  analyzingDocument: {
    description: 'Анализ файла',
    descriptionColor: 'uiKitTextSecondary',
    iconColor: 'primary',
    iconWidth: 32,
    iconHeight: 32,
    iconSize: 24,
    isProgress: true,
    isProgressIndeterminate: true,
    progressWidth: 2,
  },
  isDocument: {
    descriptionHtml: `
            <div class="ui-text ui-text_body-2 ui-kit-color-text-success">
                Документ
            </div>
            <div class="ui-kit-color-text-secondary mt-1">
                Увидят только модераторы
            </div>
        `,
  },
  isNotDocument: {
    descriptionHtml: `
            <div class="ui-text ui-text_body-2 ui-kit-color-text-success">
                Фото
            </div>
            <div class="ui-kit-color-text-secondary mt-1">
                Увидят все
            </div>
        `,
  },
}

export const PROTECTION_PROGRAM_CONTENTS = [
  {
    title: 'В чём суть программы и зачем она нужна',
    texts: [
      'Врач или клиника могут подать в суд на\u00A0автора отзыва.',
      `Если отзыв подтверждён документом,
             наши юристы возьмут на себя все судебные разбирательства и будут защищать автора.`,
      `Даже если мы проиграем (а это очень редкий случай),
             то компенсируем автору судебные расходы до\u00A050\u00A0000\u00A0${window.CURRENCY_DATA.symbol}.`,
    ],
  },
  {
    title: 'Как застраховать себя',
    texts: [
      `<ol class="pl-4">
                <li class="mb-2">
                    Сразу прикрепить документы, подтверждающие факт приёма у врача.
                </li>
                <li>
                    Согласиться
                    <a
                        target="_blank"
                        href="/info/authors-protection/"
                        class="b-link b-link_underline_hover b-link_color_neur-blue d-inline"
                    >
                        с условиями Программы,
                    </a>
                    чтобы юристы ПроДокторов представляли ваши интересы в суде.
                </li>
            </ol>`,
      'Всё это займёт всего минуту и гарантирует вашу защиту.',
      `<div class="b-banner-info mt-4">
                Наши юристы отстояли право пациентов опубликовать своё мнение о враче или клинике в 85% судебных дел.
            </div>`,
    ],
  },
  {
    title: `Для примера: как мы защитили пациента, от\u00A0которого врач требовал 1\u00A0000\u00A0000\u00A0${window.CURRENCY_DATA.symbol}`,
    texts: [
      `В 2019 году доктор Велян С. В. подал в суд на\u00A0автора отзыва
             и потребовал 1\u00A0000\u00A0000\u00A0${window.CURRENCY_DATA.symbol} за\u00A0причинение морального вреда.`,
      'Юристы ПроДокторов защищали автора абсолютно бесплатно в течение двух лет.',
      `Пациент проиграл. Но нам удалось снизить сумму компенсации
             с\u00A01\u00A0000\u00A0000\u00A0${window.CURRENCY_DATA.symbol} до\u00A020\u00A0000\u00A0${window.CURRENCY_DATA.symbol}.
             Поскольку пациент сразу подкрепил отзыв документами, портал компенсировал все судебные расходы.`,
    ],
  },
]

export const PROTECTION_PROGRAM_PANEL_CONTENTS = [
  {
    title: 'Какова вероятность, что меня вызовут в суд из-за отзыва?',
    text: `Из 3 200 000 отзывов только по 41 клиника или врач обратились в суд.
               Это\u00A00,001%. Даже если ваш отзыв попадёт в этот процент, мы будем вас защищать.
               Главное – прикрепить документы. Так что смело делитесь мнением о врачах и клиниках.
               Это поможет другим пациентам избежать некомпетентных специалистов.`,
  },
  {
    title: 'В каком случае автор отзыва получает выплату?',
    text: `Если наши юристы будут защищать его и\u00A0проиграют,
               он получит компенсацию судебных расходов после того, как решение суда вступит в силу.
               Но для этого нужно подтвердить отзыв до публикации.`,
  },
  {
    title: 'Кто оплачивает юридические расходы, пока идёт суд?',
    text: `Юристы ПроДокторов бесплатно проводят консультации,
               помогают составить жалобы, заявления, ходатайства и другие документы,
               а также представляют интересы автора в суде
               и участвуют во\u00A0всех заседаниях на территории РФ.`,
  },
]

export const USER_ACTION_AFTER_AUTH = {
  sendReview: 'sendReview',
  sendEmail: 'sendEmail',
  sendEmailCode: 'sendEmailCode',
}

export const PERSONAL_HISTORY_EXAMPLE = {
  lpu: {
    [PATIENT_TYPE_NAME.personally]: [
      {
        comment: {
          title: 'История пациента',
          text: `Я обращалась в клинику по поводу виниров.
                    Мне все подробно рассказали, показали, на все интересующие вопросы ответили.
                    Не было никаких сомнений, чтобы не выбрать клинику «Дентис».
                    Я очень довольна, что приняла решение ставить виниры в этой клинике, так как персонал очень добрый, отзывчивый, всегда спрашивали о моем состоянии.
                    Клиника тоже выглядит очень даже хорошо: внутри красивый интерьер.
                    Перед лечением я очень волновалась, потому что это был мой первый опыт в постановке виниров.
                    Но все мои сомнения и переживания исчезли после первой консультации.
                    Поэтому хочу поблагодарить и посоветовать врача Любомищенко Лину Сергеевну, которая относится к своей работе очень добросовестно и ответственно.
                    Администраторы тоже очень отзывчивые к клиентам: сделали вкусный чай,
                    пока ждала своего приёма. Обязательно буду рекомендовать эту стоматологию всем своим друзьям и родственникам.`,
        },
        commentPlus: {
          title: 'Понравилось',
          text: 'Понравилось, что в клинике чистота, очень уютно находиться, вежливый персонал.',
        },
        commentMinus: {
          title: 'Не понравилось',
          text: 'Не понравилось, что загрузка врачей очень большая, и лучше записываться заранее.',
        },
      },
      {
        comment: {
          title: 'История пациента',
          text: `Я обратился в данную клинику в первый раз в 2017 году с начальной стадией варикоза.
                    Немного затянул и только в 2021 решился на операцию. Клиника устроила во всем.
                    Вежливые администраторы и медсестры в процедурном. Был у 3 врачей, никакого навязывания, запугивания, все по делу.
                    19 марта сделал операцию, которая обошлась в 45 тыс. ${window.CURRENCY_DATA.abbreviation}. (вместо 85, как насчитали в другой профильной клинике Краснодара).
                    После операции выдали мазь, препарат, чулок на оперированную ногу, кучу лейкопластыря и бинт.
                    Очень приятно и неожиданно, что в настоящее время так может быть.
                    Рекомендую однозначно клинику и врачей (был у Марьясова А.С. и у доктора Джаладян С.Р.,
                    который и сделал операцию).`,
        },
        commentPlus: {
          title: 'Понравилось',
          text: 'Все понравилось. Рекомендую однозначно!',
        },
        commentMinus: {
          title: 'Не понравилось',
          text: '',
        },
      },
    ],
    other: [
      {
        comment: {
          title: 'История пациента',
          text: `Хочу выразить огромную благодарность всем сотрудникам клиники!
                    Я привезла отца (72 года) на подтверждение варикозной болезни нижних конечностей.
                    Нам провели полное обследование и назначили операцию, но перед самой процедурой выявили некоторые проблемы с артериальным давлением.
                    Кардиолог Коваленко Ф.А. назначил лечение, которое буквально за неделю до операции смогло стабилизировать давление (которое уже не один год беспокоило),
                    что значительно повлияло на самочувствие моего отца.
                    Профессионально сделанная операция профессором Алуханян О.А. + стабильное давление (благодаря правильно подобранному лечению) +
                    хорошее самочувствие пациента - вот итог посещения "АРД-Клиник".
                    Огромное спасибо за вашу добросовестно проделанную работу!`,
        },
        commentPlus: {
          title: 'Понравилось',
          text: 'Очень чуткое отношение к своим пациентам как со стороны врачей, так и со стороны администраторов. Приятная стоимость услуг.',
        },
        commentMinus: {
          title: 'Не понравилось',
          text: '',
        },
      },
      {
        comment: {
          title: 'История пациента',
          text: `В клинике делаем амплипульс на прямые мышцы спины, сыну 1,5 года.
                    Отличная клиника, всегда четко по записи, никаких очередей, приветливые администраторы,
                    есть зона ожидания для детей. На самой процедуре доктор находит подход к ребенку, всегда вежливые,
                    в первый день подарили игрушку моему ребенку, спасибо! С ней было легче в первый раз.
                    В этой клинике заботятся о пациентах. Рекомендую!`,
        },
        commentPlus: {
          title: 'Понравилось',
          text: 'Отношение персонала, всегда приветливые, вежливые, отличная зона ожидания, чистота.',
        },
        commentMinus: {
          title: 'Не понравилось',
          text: '',
        },
      },
    ],
  },
  doctor: {
    [PATIENT_TYPE_NAME.personally]: [
      {
        comment: {
          title: 'История пациента',
          text: `Мне 70 лет, много лет страдаю от гипертонии.
                    Сложно было подобрать комплекс лекарственных препаратов для нормализации давления.
                    Мне посоветовали обратиться к Ольге Алексеевне как к грамотному терапевту.
                    Пришлось пройти обследование, сдать анализы.
                    Я немного переживала, что новый врач может назначить много препаратов,
                    послать на большое количество платных анализов. Но я зря переживала.
                    Доктор изучила мою историю болезни. Все происходило в дружелюбной атмосфере,
                    очень внимательный врач. Мне выписали лечение для нормализации давления,
                    дали рекомендации по поводу системы питания.
                    Стараюсь принимать все лекарства вовремя, и стало лучше.`,
        },
        commentPlus: {
          title: 'Понравилось',
          text: `Ольга Алексеевна - добрый и отзывчивый доктор.
                    Она внимательно выслушивает пациента, не обрывает и терпеливо относится к пожилым людям.
                    Скоро снова пойду к ней на прием с новыми вопросами. Доверяю Ольге Алексеевне!`,
        },
        commentMinus: {
          title: 'Не понравилось',
          text: '',
        },
      },
      {
        comment: {
          title: 'История пациента',
          text: `Я попала к Наталье Александровне с множественным кариесом и с двумя разрушенными зубами передними.
                    К тому же была еще дентофобия с детства! Помимо того, что врач спасла два передних зуба, восстановив их с нуля,
                    так она еще и помогла побороть страх уколов. Перед каждой инъекцией она по моей просьбе наносила аппликационную анестезию.
                    Во время лечения каждые 2 минуты интересовалась моим самочувствием. В общем, стойко выдержала тяжелого пациента!
                    О качестве материалов пломбы говорить не буду.
                    Тут все отлично. Вопросов нет. Работа ювелирная, аккуратная.
                    Не заметила никаких навязываний услуг. Клиника шикарная.
                    После лечения в наркозе девочки с регистратуры мне названивали раза 3. Спрашивали о самочувствии.`,
        },
        commentPlus: {
          title: 'Понравилось',
          text: 'Всё. Нет чувства, что это стоматология, от чего нет страха.',
        },
        commentMinus: {
          title: 'Не понравилось',
          text: '',
        },
      },
    ],
    other: [
      {
        comment: {
          title: 'История пациента',
          text: `Моя мама нуждалась в лечении по разным болезням, так как она в возрасте и перенесла ковид.
                    Мы обратились к врачу, так как клиника рядом, и удобно по времени, и как же нам повезло, что попали к Ольге Николаевне!
                    Ольга Николаевна - врач очень внимательный и компетентный, и диагностика верная и лечение помогло.
                    Вернее не только лечение, также мама получила рекомендации по образу жизни.
                    Дело в том, что пожилому человеку с предрассудками очень сложно воспринимать новое о здоровье, о лекарствах и о образе жизни.
                    Ольга Николаевна очень хорошо общается с пациентом. Очень нам помогла.
                    Большое спасибо, низкий поклон и крепчайшего здоровья желаем доктору Ольге Николаевне!`,
        },
        commentPlus: {
          title: 'Понравилось',
          text: `Ольга Николаевна очень внимательна, комплексно смотрит на болезни и организм человека и на ситуацию с пациентом.
                    Рекомендует современные лекарства, внимательно подбирает индивидуальные препараты и их сочетание.
                    Хорошо общается с пациентом, тактично, но серьезно и достаточно строго, хорошо поясняет пациенту ситуацию.`,
        },
        commentMinus: {
          title: 'Не понравилось',
          text: 'Не отметили недостатков.',
        },
      },
      {
        comment: {
          title: 'История пациента',
          text: `Были на консультации у доктора Ольги Игоревны Лобач по поводу травмы зубика у дочери.
                    Доктор прекрасно и очень быстро установила контакт с ребёнком. Приём прошёл просто идеально: без слёз и капризов.
                    Мы смотрели любимый мультик за проведением осмотра.
                    А в конце ещё подарок выбрали. Ольга Игоревна успокоила, всё объяснила по ситуации. Спасибо огромное!`,
        },
        commentPlus: {
          title: 'Понравилось',
          text: '',
        },
        commentMinus: {
          title: 'Не понравилось',
          text: 'Нет такого',
        },
      },
    ],
  },
}
