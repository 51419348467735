import declensionByNum from 'utils/executors/declensionByNum'

const QUANTITY_DECLENSION = ['раз', 'раза', 'раз']

function getCountTextByDeclension(count) {
  const declensionText = declensionByNum(count, QUANTITY_DECLENSION)

  return `${count} ${declensionText}`
}

export default getCountTextByDeclension
