var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.MainComponent,
    {
      tag: "component",
      attrs: {
        value: _vm.value,
        width: _setup.isMobile ? undefined : 360,
        scrollable: "",
      },
      on: {
        input: function ($event) {
          return _setup.emit("input", $event)
        },
      },
    },
    [
      _c(
        "VCard",
        {
          staticClass: "ui-kit-color-text",
          attrs: { "max-height": _setup.isMobile ? undefined : 500 },
        },
        [
          !_setup.isMobile
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center pa-4" },
                    [
                      _c(
                        "VIcon",
                        {
                          staticClass: "mr-4",
                          attrs: { color: "uiKitIconSecondary", size: "24" },
                          on: {
                            click: function ($event) {
                              return _setup.emit("input", false)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          ui-icon-close-not-a-circle\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ui-text ui-text_h6 ui-kit-color-text" },
                        [
                          _vm._v(
                            "\n          Дарим скидку 300 ₽ за честный отзыв\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("VDivider"),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _setup.isMobile
            ? _c("div", { staticClass: "d-flex align-center px-4 pt-6 mb-2" }, [
                _c(
                  "div",
                  { staticClass: "ui-text ui-text_h6 ui-kit-color-text" },
                  [
                    _vm._v(
                      "\n        Дарим скидку 300 ₽ за честный отзыв\n      "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "VCardText",
            { staticClass: "px-4", class: _setup.isMobile ? "py-0" : "py-4" },
            _vm._l(_setup.INFO_LIST_ITEMS, function (listItem, index) {
              return _c(
                _setup.InfoListItem,
                {
                  key: index,
                  class: { "mt-4": index > 0 },
                  attrs: { number: index + 1 },
                },
                [_vm._v("\n        " + _vm._s(listItem) + "\n      ")]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "VSheet",
            { class: _setup.isMobile ? "px-4 pb-4 mt-5" : "pa-2" },
            [
              _c(
                "VBtn",
                {
                  attrs: { color: "primary", depressed: "", block: "" },
                  on: { click: _setup.handleClickButton },
                },
                [_vm._v("\n        Хорошо\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }