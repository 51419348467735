<template>
  <VForm
    ref="review-form"
    class="review-form"
    data-qa="review_form"
  >
    <SelectBottomSheet
      ref="review-select-patient"
      data-qa="review_select_patient"
      :use-bottom-sheet="isMobile"
      :select-props="{
        ref: 'review-field-patient',
        label: 'Кто был пациентом',
        itemText: 'title',
        itemTextSecondary: 'subtitle',
        rules: rulesValidationSelectedPatient,
      }"
      :items="patientsFromAppointment"
      :selected-item="inputData.selectedPatient"
      @select:change="updateSelectedPatient"
    />
    <VExpandTransition>
      <div v-show="isSelectedPatientAnotherMan">
        <div class="mb-8">
          <VTextField
            ref="review-field-other-patient"
            data-qa="review-field_other_patient"
            outlined
            label="Кем вам приходится человек из отзыва"
            :value="inputData.otherPatientFromReview"
            :rules="rulesValidationOtherPatientFromReview"
            :maxlength="MAX_FIELD_OTHER_PATIENT_LENGTH"
            @input="updateOtherPatientFromReview"
          />
          <BaseAlert
            type="yellow"
            icon="ui-icon-circle-warning"
            data-qa="review-field_other_patient_alert"
          >
            <div class="ui-text ui-text_subtitle-2 mb-2">
              Отзыв должен оставлять сам пациент или его близкий родственник
            </div>
            <div class="ui-text ui-text_body-2">
              В другом случае отзыв будет отклонён во время проверки.
            </div>
          </BaseAlert>
        </div>
      </div>
    </VExpandTransition>

    <SelectBottomSheet
      v-if="itemsLpuAddresses.length"
      ref="review-select-address"
      data-qa="review_select_address"
      :disabled="commonData.isReviewSourcePrs"
      :use-bottom-sheet="isMobile"
      :select-props="{
        ref: 'review-field-address',
        label: 'Клиника',
        itemValue: 'id',
        itemText: 'lpuName',
        itemTextSecondary: 'address',
        rules: rulesValidationSelectedLpuAddressId,
      }"
      :items="itemsLpuAddresses"
      :selected-item="inputData.selectedLpuAddressId"
      @select:change="updateSelectedLpuAddressId"
    />
    <VExpandTransition>
      <VTextField
        v-show="isSelectedLpuAddressOther"
        ref="review-field-other-address"
        data-qa="review_field_other_address"
        outlined
        label="Название клиники и адрес"
        :value="inputData.otherLpuNameAndAddress"
        :rules="rulesValidationOtherLpuNameAndAddress"
        :maxlength="MAX_FIELD_OTHER_LPU_NAME_LENGTH"
        @input="updateOtherLpuNameAndAddress"
      />
    </VExpandTransition>

    <VSheet
      v-if="isPowerInfoPlateVisible"
      class="d-flex align-center px-3 py-4 mb-7 cursor-pointer"
      color="uiKitBgWarning"
      rounded="lg"
      @click="handleClickReviewPowerInfo"
    >
      <VIcon
        class="ui-icon_type_40 ui-kit-bg-warning flex-shrink-0 mr-4"
        color="uiKitBgGray0"
      >
        ui-icon-piggy-bank-40
      </VIcon>
      <div class="ui-text ui-text_subtitle-1 ui-kit-color-text-warning mr-4">
        Дарим скидку 300&nbsp;₽ за&nbsp;честный отзыв о приёме у врача в этой клинике
      </div>
      <VSpacer />
      <VIcon color="uiKitTextWarning">
        ui-icon-arrow-right
      </VIcon>
    </VSheet>

    <DatePickerMonth
      ref="review-select-date"
      data-qa="review_select_date"
      :use-dialog="isMobile"
      :text-field-props="{
        ref: 'review-field-date',
        label: 'Дата приёма',
        rules: rulesValidationSelectedDate,
      }"
      :date-picker-props="{
        min: minSelectedDate,
        max: maxSelectedDate,
      }"
      :selected-date="inputData.selectedDate"
      @date-picker:change="updateSelectedDate"
    />

    <ReviewFormTextareaAlerts />

    <div data-qa="review_field_comment">
      <!-- Поле name="comment" используется модулем BehavioralFactors -->
      <VTextarea
        ref="review-field-comment"
        data-qa="review_field_comment_input"
        outlined
        auto-grow
        :name="TEXTAREA_NAMES.comment"
        :error="inputData.comment.length > MAX_REVIEW_FIELD_LENGTH"
        :counter="inputData.comment.length > MAX_REVIEW_FIELD_LENGTH"
        :maxlength="OVER_MAX_REVIEW_FIELD_LENGTH"
        rows="10"
        label="Ваша история"
        class="review-form__textarea"
        :placeholder="commentPlaceholder"
        :rules="rulesValidationComments"
        :value="inputData.comment"
        @input="handleInputTextarea(TEXTAREA_NAMES.comment, $event)"
        @focus="handleFocusTextarea(TEXTAREA_NAMES.comment, $event)"
        @blur="handleBlurTextarea(TEXTAREA_NAMES.comment, $event)"
      />
    </div>

    <div data-qa="review_field_comment_plus">
      <!-- Поле name="comment_plus" используется модулем BehavioralFactors. -->
      <VTextarea
        ref="review-field-comment-plus"
        data-qa="review_field_comment_plus_input"
        outlined
        auto-grow
        :name="TEXTAREA_NAMES.commentPlus"
        :error="inputData.commentPlus.length > MAX_REVIEW_FIELD_LENGTH"
        :counter="inputData.commentPlus.length > MAX_REVIEW_FIELD_LENGTH"
        :maxlength="OVER_MAX_REVIEW_FIELD_LENGTH"
        rows="4"
        label="Понравилось"
        class="review-form__textarea"
        placeholder="Здесь можно указать главные плюсы"
        :rules="rulesValidationComments"
        :value="inputData.commentPlus"
        @input="handleInputTextarea(TEXTAREA_NAMES.commentPlus, $event)"
        @focus="handleFocusTextarea(TEXTAREA_NAMES.commentPlus, $event)"
        @blur="handleBlurTextarea(TEXTAREA_NAMES.commentPlus, $event)"
      />
    </div>

    <div data-qa="review_field_comment_minus">
      <!-- Поле name="comment_minus" используется модулем BehavioralFactors -->
      <VTextarea
        ref="review-field-comment-minus"
        data-qa="review_field_comment_minus_input"
        :name="TEXTAREA_NAMES.commentMinus"
        :error="inputData.commentMinus.length > MAX_REVIEW_FIELD_LENGTH"
        :counter="inputData.commentMinus.length > MAX_REVIEW_FIELD_LENGTH"
        :maxlength="OVER_MAX_REVIEW_FIELD_LENGTH"
        outlined
        auto-grow
        rows="4"
        label="Не понравилось"
        class="review-form__textarea"
        placeholder="Какие недостатки вы отметили?"
        :rules="rulesValidationComments"
        :value="inputData.commentMinus"
        @input="handleInputTextarea(TEXTAREA_NAMES.commentMinus, $event)"
        @focus="handleFocusTextarea(TEXTAREA_NAMES.commentMinus, $event)"
        @blur="handleBlurTextarea(TEXTAREA_NAMES.commentMinus, $event)"
      />
    </div>
    <ReviewPowerDiscountInfo v-model="isReviewPowerDiscountInfoVisible" />
  </VForm>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { debounce } from 'utils'
import {
  scrollToTextarea,
  updateTippyPosition,
  createTippyFeedbackProgress,
  programmaticallyScrollReady,
} from 'components/common/ReviewPage/functions'
import DatePickerMonth from 'components/common/core/DatePickerMonth/DatePickerMonth'
import SelectBottomSheet from 'components/common/core/SelectBottomSheet/SelectBottomSheet'
import BaseAlert from 'components/common/core/BaseAlert'
import ReviewFormTextareaAlerts from 'components/common/ReviewPage/components/common/ReviewFormTextareaAlerts'
import {
  TEXTAREA_NAMES,
  MAX_FIELD_OTHER_PATIENT_LENGTH,
  MAX_FIELD_OTHER_LPU_NAME_LENGTH,
} from 'components/common/ReviewPage/constants'
import {
  useSteppersStore,
  useDraftInfoStore,
  useReviewPageDataStore,
  useReviewFormDataStore,
} from 'components/common/ReviewPage/stores'
// eslint-disable-next-line import/extensions
import ReviewPowerDiscountInfo from 'components/common/ReviewPage/components/common/ReviewPowerDiscountInfo.vue'

export default {
  name: 'ReviewForm',
  components: {
    BaseAlert,
    DatePickerMonth,
    SelectBottomSheet,
    ReviewFormTextareaAlerts,
    ReviewPowerDiscountInfo,
  },
  data: vm => ({
    fieldRefs: null,
    textareaRefs: null,
    isMobile: window.MOBILE_VERSION,
    vuetifyColors: vm.$vuetify.theme.themes.light,
    tippyTargetSelector: '.review-form__textarea .v-input__slot',
    tippyFeedbackProgressInstances: null,
    isReviewPowerDiscountInfoVisible: false,
    debouncedUpdateIsValidForm: debounce(vm.updateIsValidForm, 300),
    debouncedUpdateFastValidationData: debounce(vm.updateFastValidationData, 300),
    MAX_REVIEW_FIELD_LENGTH: window.MAX_REVIEW_FIELD_LENGTH,
    /**
     * При сохранении черновика есть ограничение на максимальное количество символов = 16к,
     * иначе падает ошибка.
     * Также нужно оставить вывод ошибки при превышении лимита,
     * поэтому у OVER_MAX_REVIEW_FIELD_LENGTH такое значение.
     * */
    OVER_MAX_REVIEW_FIELD_LENGTH: window.MAX_REVIEW_FIELD_LENGTH + 100,
    TEXTAREA_NAMES,
    MAX_FIELD_OTHER_PATIENT_LENGTH,
    MAX_FIELD_OTHER_LPU_NAME_LENGTH,
  }),
  computed: {
    ...mapState(useReviewFormDataStore, [
      'inputData',
      'patientsFromAppointment',
      'minSelectedDate',
      'maxSelectedDate',
      'itemsLpuAddresses',
      'isSelectedLpuAddressInPrs',
      'isVisibleAlertLengthNeed',
      'isSelectedLpuAddressOther',
      'isSelectedPatientAnotherMan',
      'isSelectedPatientPersonally',
      'rulesValidationComments',
      'rulesValidationSelectedDate',
      'rulesValidationSelectedPatient',
      'rulesValidationSelectedLpuAddressId',
      'rulesValidationOtherPatientFromReview',
      'rulesValidationOtherLpuNameAndAddress',
    ]),
    ...mapState(useReviewPageDataStore, [
      'commonData',
      'reviewObjectTypes',
    ]),
    ...mapState(useDraftInfoStore, [
      'saveDraftDebounced',
    ]),
    ...mapState(useSteppersStore, [
      'currentStep',
    ]),
    commentPlaceholder() {
      let text

      if (this.reviewObjectTypes.isLpu) {
        text = 'Расскажите, почему обратились в клинику, как проходили приёмы, помогло ли лечение, какое впечатление осталось у вас от медперсонала и здания клиники в целом. '
      } else {
        text = 'Расскажите, почему обратились к врачу, как прошёл приём, помогло ли лечение. '
      }

      if (this.isSelectedPatientPersonally) {
        if (this.reviewObjectTypes.isLpu) {
          text += 'Пишите от первого лица: «Я обращался...».'
        } else {
          text += 'Пишите от первого лица: «Я обращался..., врач расспросил меня, осмотрел...».'
        }
      } else {
        text += 'Если пациентом были не вы, а ваш родственник, пожалуйста, укажите степень родства («были на приёме с сыном», «к врачу обращалась моя мама»).'
      }

      return text
    },
    isPowerInfoPlateVisible() {
      if (this.reviewObjectTypes.isLpu) {
        return false
      }

      return this.isSelectedLpuAddressInPrs
    },
  },
  watch: {
    isVisibleAlertLengthNeed() {
      updateTippyPosition({
        instances: this.tippyFeedbackProgressInstances,
        timeoutMs: 300, // время появления алерта
      })
    },
    inputData: {
      deep: true,
      handler() {
        this.debouncedUpdateIsValidForm(false)
        this.saveDraftDebounced()
      },
    },
    /**
     * Производит редирект на выбранную в селекте клинику
     * */
    'inputData.selectedLpuAddressId': {
      handler(newLpuAddressId) {
        if (!this.reviewObjectTypes.isLpu) {
          return
        }

        const { lpuId } = this.itemsLpuAddresses.find(
          lpuAddress => lpuAddress.id === newLpuAddressId,
        )

        window.location.assign(`/new/rate/lpu/${lpuId}/`)
      },
    },
  },
  mounted() {
    this.textareaRefs = [
      this.$refs['review-field-comment'],
      this.$refs['review-field-comment-plus'],
      this.$refs['review-field-comment-minus'],
    ]

    this.fieldRefs = [
      this.$refs['review-select-patient']?.$refs['review-field-patient'],
      this.$refs['review-select-address']?.$refs['review-field-address'],
      this.$refs['review-select-date']?.$refs['review-field-date'],
      this.$refs['review-field-other-patient'],
      this.$refs['review-field-other-address'],
      ...this.textareaRefs,
    ]

    this.updateReviewFormRef(this.$refs['review-form'])
    this.updateReviewFormFieldRefs(this.fieldRefs.filter(ref => !!ref))
    this.updateTextareaHeight()

    this.tippyFeedbackProgressInstances = createTippyFeedbackProgress(this.tippyTargetSelector)
  },
  methods: {
    ...mapActions(useReviewFormDataStore, [
      'updateComment',
      'updateCommentPlus',
      'updateCommentMinus',
      'updateSelectedDate',
      'updateSelectedPatient',
      'updateSelectedLpuAddressId',
      'updateOtherPatientFromReview',
      'updateOtherLpuNameAndAddress',
      'updateReviewFormFieldRefs',
      'updateFastValidationData',
      'updateIsTextareaFocused',
      'updateReviewFormRef',
      'updateIsValidForm',
    ]),
    handleInputTextarea(textareaName, textareaValue) {
      this.updateCommentInStore(textareaName, textareaValue)

      this.debouncedUpdateFastValidationData({ isUserAgreeNonBadWords: false })

      if (this.isMobile) {
        updateTippyPosition({
          instances: this.tippyFeedbackProgressInstances,
        })
      }
    },
    handleFocusTextarea(textareaName) {
      const isTextareaBig = textareaName === TEXTAREA_NAMES.comment

      this.updateIsTextareaFocused(true)

      programmaticallyScrollReady(() => {
        this.updateScrollPosition({ isTextareaBig })
      })
    },
    handleBlurTextarea(textareaName, { target }) {
      this.updateCommentInStore(textareaName, target.value.trim())
      this.updateIsTextareaFocused(false)
    },
    updateScrollPosition({ isTextareaBig }) {
      const textareaElement = document.activeElement.closest('.v-input__slot')

      if (!textareaElement) {
        return
      }

      scrollToTextarea({ textareaElement, isTextareaBig })
    },
    /**
     * Обновляет высоту textarea когда они видимы на странице.
     * Перерасчёт нужен, так как поле может быть заполнено из черновика длинным текстом.
     * */
    updateTextareaHeight() {
      const unwatch = this.$watch('currentStep', newValue => {
        if (newValue === 2) {
          this.textareaRefs.forEach(t => t.calculateInputHeight())

          unwatch()
        }
      })
    },
    updateCommentInStore(textareaName, textareaValue) {
      const updateCommentFn = this.getUpdateCommentMutationFn(textareaName)

      updateCommentFn(textareaValue)
    },
    getUpdateCommentMutationFn(textareaName) {
      if (textareaName === TEXTAREA_NAMES.comment) {
        return this.updateComment
      }

      if (textareaName === TEXTAREA_NAMES.commentPlus) {
        return this.updateCommentPlus
      }

      if (textareaName === TEXTAREA_NAMES.commentMinus) {
        return this.updateCommentMinus
      }
    },
    handleClickReviewPowerInfo() {
      this.isReviewPowerDiscountInfoVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
.review-form {
  margin: 0 auto;
  max-width: 472px;
}
</style>

<style lang="scss">
.review-form__textarea textarea {
  line-height: 125% !important;
}
</style>
