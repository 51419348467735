<template>
  <div
    class="review-window-success"
    :class="{ 'review-window-success_mobile': isMobile }"
    data-qa="review_window_success"
  >
    <ReviewPreloader
      v-if="submittingReviewData.isLoading"
      text="Отправляем..."
    />
    <div
      v-else-if="submittingReviewData.isSuccessSubmit"
      class="review-window-success__inner text-center"
    >
      <div :class="{ 'my-auto': isMobile }">
        <VImg
          contain
          width="200"
          height="200"
          class="flex-grow-0 mx-auto mb-4"
          data-qa="review_window_success_img"
          alt="Хороший отзыв"
          src="/static/_v1/pd/mascots/review-success.svg"
        />
        <div
          data-qa="review_window_success_title"
          class="ui-text ui-kit-color-text mb-4"
          :class="{
            'ui-text_h6': isMobile,
            'ui-text_h5': !isMobile,
          }"
        >
          Отзыв отправлен
        </div>
        <div
          class="ui-text ui-text_body-1 ui-kit-color-text mb-4"
          data-qa="review_window_success_description"
        >
          Проверим в течение 1-3 дней.
          <template
            v-if="userData.email"
          >
            <template v-if="commonData.isReviewSourcePrs">
              <div class="mb-2">
                Сообщим о результате и пришлём промокод на&nbsp;почту

                <span class="ui-text ui-text_subtitle-1">
                  {{ userData.email }}
                </span>
              </div>
            </template>
            <template v-else>
              <div>
                О результате сообщим на почту
              </div>
              <div class="mb-2 ui-text ui-text_subtitle-1">
                {{ userData.email }}
              </div>
            </template>
            <VBtn
              v-if="!userData.isSetEmailFirstTime"
              text
              class="ui-kit-color-primary"
              color="primary"
              @click="handleChangeEmailButton"
            >
              Изменить почту
            </VBtn>
          </template>
          <template
            v-else
          >
            <template v-if="commonData.isReviewSourcePrs">
              <div>
                Когда опубликуем отзыв,
              </div>
              <div>
                пришлём промокод на скидку {{ commonData.prsDiscount }} {{ currency.symbol }}.
              </div>
            </template>
            <template v-else>
              Отслеживать статус проверки можно в личном кабинете — МедТочке.
            </template>
          </template>
        </div>
        <div
          v-if="!commonData.isPdMobile"
          class="review-window-success__banner ui-text ui-text_body-1 mb-4"
          data-qa="review_window_success_banner"
        >
          <p class="mb-2">
            Если вы не были в этой клинике
            и оставили отзыв по заказу клиники или
            врача, сообщите нам об этом и получите вознаграждение
          </p>
          <a
            href="/info/rate-fraud-free/"
            target="_blank"
          >Подробнее</a>
        </div>
      </div>
      <!-- eslint-disable vue/no-template-target-blank -->
      <VBtn
        v-if="!isMobile"
        class="mb-4"
        depressed
        color="primary"
        target="_blank"
        data-qa="review_window_success_mtlink"
        :href="commonData.medtochkaUrlOnReview"
      >
        Посмотреть отзыв
      </VBtn>
      <!-- eslint-enable vue/no-template-target-blank -->
      <div
        class="ui-text ui-text_body-2 ui-kit-color-text-secondary mb-4"
        data-qa="review_window_success_caption"
      >
        Благодаря вашему мнению пациенты сделают
        правильный выбор. Спасибо!
      </div>
      <BottomPanel
        v-if="isMobile"
        fixed
        no-height
      >
        <template #container>
          <div class="px-2 pt-2 pb-6">
            <!-- eslint-disable vue/no-template-target-blank -->
            <VBtn
              block
              depressed
              color="primary"
              target="_blank"
              data-qa="review_window_success_mtlink"
              :href="commonData.medtochkaUrlOnReview"
            >
              Посмотреть отзыв
            </VBtn>
            <!-- eslint-enable vue/no-template-target-blank -->
          </div>
        </template>
      </BottomPanel>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import BottomPanel from 'components/common/core/BottomPanel/BottomPanel'
import ReviewPreloader from 'components/common/ReviewPage/components/common/ReviewPreloader'
import { sendYaGoal } from 'components/common/ReviewPage/functions'
import { GET_USER_EMAIL_YA_GOALS } from 'yandexGoals'
import {
  useUserDataStore,
  useReviewPageDataStore,
  useReviewFormDataStore,
} from 'components/common/ReviewPage/stores'
import CurrencyData from 'components/common/mixins/CurrencyData'

export default {
  name: 'ReviewSubmitting',
  components: {
    BottomPanel,
    ReviewPreloader,
  },
  mixins: [
    CurrencyData,
  ],
  data: () => ({
    isMobile: window.MOBILE_VERSION,
  }),
  computed: {
    ...mapState(useReviewPageDataStore, [
      'commonData',
    ]),
    ...mapState(useReviewFormDataStore, [
      'submittingReviewData',
    ]),
    ...mapState(useUserDataStore, [
      'userData',
    ]),
  },
  watch: {
    'submittingReviewData.isSuccessSubmit': {
      handler() {
        if (this.submittingReviewData.isSuccessSubmit && this.userData.email) {
          sendYaGoal(GET_USER_EMAIL_YA_GOALS.successScreenWithEmail)
        }
      },
    },
  },
  methods: {
    ...mapActions(useReviewFormDataStore, [
      'updateIsVisibleReviewGetUserEmail',
    ]),
    handleChangeEmailButton() {
      this.updateIsVisibleReviewGetUserEmail(true)
      sendYaGoal(GET_USER_EMAIL_YA_GOALS.clickChangeEmail)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.review-window-success {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &_mobile {
    min-height: 100dvh;
    padding: 8px 24px 72px;
  }

  &_mobile &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &:not(&_mobile) {
    padding: 24px;
    min-height: 664px;
  }

  &:not(&_mobile) &__inner {
    margin: 0 auto;
    max-width: 316px;
  }

  &__banner {
    padding: 16px;
    text-align: left;
    border-radius: 8px;
    background: linear-gradient(92deg, $ui-kit-brand, $ui-kit-primary);
    color: #FFFFFF;

    a {
      color: currentColor;
    }
  }
}
</style>
