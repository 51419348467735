import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'
import type { Rate } from 'components/common/ReviewPage/components/common/SupplementStep/types'

const useSupplementStepStore = defineStore('supplementStepStore', () => {
  const rate: Ref<Rate> = ref({
    id: 0,
    createdDateString: '',
    supplements: [],
    avgAssessment: 0,
    assessments: [],
    comments: [],
  })
  const remainingCountOfSupplements = ref(0)
  const supplementText = ref('')
  const supplementOptions = ref({
    textMinLength: 0,
    textMaxLength: 0,
    remainingCount: 0,
    maxCount: 0,
  })

  return {
    rate,
    remainingCountOfSupplements,
    supplementText,
    supplementOptions,
  }
})

export default useSupplementStepStore
