<template>
  <v-menu
    data-qa="rate_assessments"
    attach=""
    :open-on-hover="!isMobile"
    :close-on-content-click="false"
    :disabled="!assessmentsTransformed.length"
    offset-y
    left
    :allow-overflow="false"
  >
    <template #activator="{ attrs, on }">
      <div
        data-qa="rate_assessments_activator"
        v-bind="attrs"
        class="patient-avg-rate-assessment"
        :class="[
          avgAssessmentColors,
          { 'cursor-pointer': assessmentsTransformed.length },
        ]"
        v-on="on"
      >
        <v-icon
          color="uiKitRating"
          size="small"
        >
          ui-icon-rating-star-filled
        </v-icon>
        <span
          data-qa="rate_avg_assessment"
          class="text-subtitle-2 uiKitText--text ml-1"
          v-text="normalizeAvgAssessmentText"
        />
        <span
          data-qa="rate_avg_assessment_text"
          class="ml-2"
          v-text="avgAssessmentText"
        />
      </div>
    </template>
    <div
      data-qa="rate_assessments_list"
      class="pa-5 uiKitBgGray0 elevation-4"
    >
      <div
        v-for="(assessment, index) in assessmentsTransformed"
        :key="assessment.name"
        data-qa="rate_assessments_item"
        :class="{ 'mt-4': index > 0 }"
      >
        <div
          data-qa="rate_assessments_item_name"
          class="text-no-wrap"
          v-text="assessment.name"
        />
        <div class="d-flex">
          <v-icon
            v-for="i in assessment.filledStars"
            :key="`filled-${i}`"
            data-qa="rate_assessments_item_filled_star"
            color="uiKitRating"
            :class="{ 'ml-1': i > 1 }"
            small
          >
            ui-icon-rating-star-filled
          </v-icon>
          <v-icon
            v-for="i in assessment.outlineStars"
            :key="`outline-${i}`"
            data-qa="rate_assessments_item_outline_star"
            class="ml-1"
            color="uiKitRating"
            small
          >
            ui-icon-rating-star-outline
          </v-icon>
          <div
            data-qa="rate_assessments_item_status"
            class="text-transform-capitalize ml-2"
            :class="assessment.color"
            v-text="assessment.status"
          />
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import {
  AssessmentBoundaries,
  AssessmentTextColors,
  AssessmentTexts,
  AvgAssessmentBoundaries,
  AvgAssessmentColors,
  StarCounts,
} from 'www/vue_components/common/ReviewPage/components/common/SupplementStep/components/RateDialog/components/RateAssessments/constants'
import useSupplementStepStore from 'components/common/ReviewPage/components/common/SupplementStep/stores/useSupplementStepStore'

const isMobile = window.MOBILE_VERSION

const supplementStepStore = useSupplementStepStore()
const { rate } = storeToRefs(supplementStepStore)

function getAssessmentTextColor(assessmentValue: number | null) {
  if (assessmentValue === null) {
    return AssessmentTextColors.default
  }

  const normalizeAssessmentValue = (assessmentValue + 2) * 1.25

  if (normalizeAssessmentValue < AvgAssessmentBoundaries.maxError) {
    return AssessmentTextColors.error
  }

  if (normalizeAssessmentValue <= AvgAssessmentBoundaries.maxWarning) {
    return AssessmentTextColors.warning
  }

  return AssessmentTextColors.success
}

const assessmentsTransformed = computed(() => {
  if (!rate.value.assessments) {
    return []
  }

  return rate.value.assessments.map(
    assessment => {
      const filledStars = assessment.value === null
        ? StarCounts.min
        : assessment.value + 3

      return {
        ...assessment,
        color: getAssessmentTextColor(assessment.value),
        filledStars,
        outlineStars: StarCounts.max - filledStars,
      }
    },
  )
})

const avgAssessmentText = computed(() => {
  if (rate.value.avgAssessment <= AssessmentBoundaries.maxVeryBad) {
    return AssessmentTexts.veryBad
  }

  if (rate.value.avgAssessment < AssessmentBoundaries.maxBad) {
    return AssessmentTexts.bad
  }

  if (rate.value.avgAssessment <= AssessmentBoundaries.maxNormal) {
    return AssessmentTexts.normal
  }

  if (rate.value.avgAssessment < AssessmentBoundaries.maxGood) {
    return AssessmentTexts.good
  }

  return AssessmentTexts.veryGood
})

const avgAssessmentColors = computed(() => {
  if (rate.value.avgAssessment < AvgAssessmentBoundaries.maxError) {
    return AvgAssessmentColors.error
  }

  if (rate.value.avgAssessment <= AvgAssessmentBoundaries.maxWarning) {
    return AvgAssessmentColors.warning
  }

  return AvgAssessmentColors.success
})

const normalizeAvgAssessment = computed(() => {
  const starsRate = (rate.value.avgAssessment + 2) * 1.25

  return Math.round(starsRate * 10) / 10
})

const normalizeAvgAssessmentText = computed(() => `${normalizeAvgAssessment.value}.0`.slice(0, 3))
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.cursor-pointer {
  cursor: pointer !important;
}

.patient-avg-rate-assessment {
  display: inline-flex;
  align-items: center;
  border-radius: $border-radius-md;
  padding: 0 8px;
  height: 32px;
  cursor: default;
}
</style>
