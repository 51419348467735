<template>
  <div data-qa="review_phone_confirmation">
    <VBtn
      v-if="userData.isPhoneConfirmedByCall"
      block
      depressed
      class="mb-4"
      color="primary"
      data-qa="review_send_button"
      @click="handleClickSendReview"
    >
      <VProgressCircular
        v-if="isSomeImageSending"
        data-qa="review_send_button_progress"
        color="white"
        size="22"
        width="3"
        indeterminate
      />
      <template v-else>
        Отправить отзыв
      </template>
    </VBtn>
    <PolicyAndTerms />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import {
  useUserDataStore,
  useReviewImagesStore,
  useReviewFormDataStore,
} from 'components/common/ReviewPage/stores'
import PolicyAndTerms from 'components/common/ReviewPage/components/common/PolicyAndTerms'

export default {
  name: 'ReviewPhoneConfirmation',
  components: {
    PolicyAndTerms,
  },
  computed: {
    ...mapState(useUserDataStore, [
      'userData',
    ]),
    ...mapState(useReviewFormDataStore, [
      'isValidForm',
    ]),
    ...mapState(useReviewImagesStore, [
      'isSomeImageSending',
    ]),
  },
  methods: {
    ...mapActions(useReviewFormDataStore, [
      'sendReviewFormData',
      'validateForm',
    ]),
    async handleClickSendReview() {
      await this.validateForm()

      if (this.isValidForm) {
        await this.sendReviewFormData()
      }
    },
  },
}
</script>
