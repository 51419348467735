var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "uiKitText--text", attrs: { "data-qa": "rate_comments" } },
    _vm._l(_setup.formattedComments, function ({ type, text }, commentIndex) {
      return _c(
        "div",
        {
          key: commentIndex,
          class: {
            "mt-4": commentIndex > 0,
          },
          attrs: { "data-qa": `rate_comments_comment_${type}` },
        },
        [
          _c("div", {
            staticClass: "text-subtitle-2 mb-2",
            attrs: { "data-qa": "rate_comments_comment_title" },
            domProps: { textContent: _vm._s(_setup.COMMENT_TITLES[type]) },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "text-body-1 text-pre-line text-break",
            attrs: { "data-qa": "rate_comments_comment_text" },
            domProps: {
              innerHTML: _vm._s(text /* eslint-disable-line vue/no-v-html */),
            },
          }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }