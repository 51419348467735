var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    _vm._b(
      {
        attrs: {
          width: _vm.fullscreen ? "100%" : _setup.MODAL_WIDTHS[_vm.modalType],
          fullscreen: _vm.fullscreen,
        },
        model: {
          value: _setup.isVisible,
          callback: function ($$v) {
            _setup.isVisible = $$v
          },
          expression: "isVisible",
        },
      },
      "v-dialog",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "div",
        {
          staticClass: "base-dialog",
          class: [
            `base-dialog_${_vm.modalType}`,
            {
              "h-100": _vm.fullscreen,
            },
          ],
          attrs: { "data-qa": "base_dialog" },
        },
        [
          _c(
            "div",
            {
              staticClass: "base-dialog__body",
              class: {
                "rounded-sm elevation-24": !_vm.fullscreen,
                "h-100 w-100": _vm.fullscreen,
              },
              attrs: { "data-qa": "base_dialog_body" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "base-dialog__toolbar",
                  class: {
                    "px-2": _vm.hasPrependBtn || _vm.$slots.prependBtn,
                  },
                },
                [
                  _vm.title || _vm.hasPrependBtn || _vm.$slots.prependBtn
                    ? _c(
                        "div",
                        {
                          staticClass: "d-flex align-center",
                          attrs: { "data-qa": "base_dialog_title" },
                        },
                        [
                          _vm.hasPrependBtn
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "ml-0 mr-8",
                                  attrs: {
                                    "data-qa": "base_dialog_prepend_btn",
                                    color: "uiKitText",
                                    height: "auto",
                                    width: "auto",
                                    icon: true,
                                    text: "",
                                  },
                                  on: { click: _setup.handleClickPrependBtn },
                                },
                                [
                                  _vm._t("prependBtn", function () {
                                    return [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: "uiKitText",
                                            size: "24",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                ui-icon-close-not-a-circle\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "mr-auto" }, [
                            _vm.title
                              ? _c("div", {
                                  staticClass: "text-h6 uiKitText--text",
                                  domProps: { textContent: _vm._s(_vm.title) },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.subtitle
                              ? _c("div", {
                                  staticClass:
                                    "text-body-2 uiKitTextSecondary--text",
                                  domProps: {
                                    textContent: _vm._s(_vm.subtitle),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._t("subheader"),
                ],
                2
              ),
              _vm._v(" "),
              _vm.hasDivider
                ? _c("v-divider", {
                    attrs: { "data-qa": "base_dialog_divider" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "content",
                  staticClass:
                    "base-dialog__content uiKitTextSecondary--text text-body-1",
                  class: [
                    {
                      "pt-0": !_vm.hasDivider,
                      "px-4 pb-4": _vm.fullscreen,
                      "overflow-y-auto": _vm.isOverflowYAuto,
                    },
                    _vm.contentClass,
                  ],
                  attrs: { "data-qa": "base_dialog_content" },
                },
                [
                  _vm._t("default", function () {
                    return [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.text /* eslint-disable-line vue/no-v-html */
                          ),
                        },
                      }),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _setup.closeBtn.text || _setup.confirmBtn.text
                ? _c(
                    "div",
                    {
                      staticClass: "base-dialog__actions",
                      class: { "pa-2": _vm.fullscreen },
                    },
                    [
                      !_vm.fullscreen ? _c("v-spacer") : _vm._e(),
                      _vm._v(" "),
                      _setup.closeBtn.text
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "base-dialog__action-btn",
                              class: {
                                "px-2": _setup.closeBtn.variant === "text",
                                "flex-fill": _vm.fullscreen,
                              },
                              attrs: {
                                "data-qa": "base_dialog_close_btn",
                                height: "40",
                                text: _setup.closeBtn.variant === "text",
                                depressed:
                                  _vm.confirmBtnProps.variant === "flat",
                                color: _vm.closeBtnProps.color,
                              },
                              on: { click: _setup.handleClickClose },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.closeBtnProps.text) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _setup.confirmBtn.text
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "base-dialog__action-btn",
                              class: {
                                "px-2": _setup.confirmBtn.variant === "text",
                                "flex-fill": _vm.fullscreen,
                              },
                              attrs: {
                                "data-qa": "base_dialog_confirm_btn",
                                height: "40",
                                text: _setup.confirmBtn.variant === "text",
                                depressed:
                                  _vm.confirmBtnProps.variant === "flat",
                                color: _setup.confirmBtn.color,
                              },
                              on: { click: _setup.handleClickConfirm },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_setup.confirmBtn.text) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }