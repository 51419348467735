<template>
  <AlertInfo>
    <div
      class="text-subtitle-1 uiKitText--text mb-2"
      v-text="title"
    />
    <div
      class="text-body-2 uiKitText--text mb-2"
      v-text="`Вы можете дополнить отзыв ещё ${remainingCountOfSupplementsText}`"
    />
    <div
      class="text-body-2 primary--text link-decoration-underline cursor-pointer"
      @click="open"
      v-text="'Посмотреть текущий отзыв'"
    />
  </AlertInfo>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
// eslint-disable-next-line import/extensions
import AlertInfo from 'components/common/core/AlertInfo/AlertInfo.vue'
import { useReviewPageDataStore } from 'components/common/ReviewPage/stores'
import getCountTextByDeclension from 'components/common/ReviewPage/functions/getCountTextByDeclension'
import useRateDialogStore from 'components/common/ReviewPage/components/common/SupplementStep/components/RateDialog/stores/useRateDialogStore'
import useSupplementStepStore from 'components/common/ReviewPage/components/common/SupplementStep/stores/useSupplementStepStore'

const reviewPageDataStore = useReviewPageDataStore()
const supplementStepStore = useSupplementStepStore()
const { open } = useRateDialogStore()

const remainingCountOfSupplementsText = computed(
  () => getCountTextByDeclension(supplementStepStore.supplementOptions.remainingCount),
)
const title = computed(() => (
  reviewPageDataStore.reviewObjectTypes.isDoctor
    ? 'Вы уже оставляли отзыв на этого врача'
    : 'Вы уже оставляли отзыв на эту клинику'
))
</script>
