var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.AlertInfo, [
    _c("div", {
      staticClass: "text-subtitle-1 uiKitText--text mb-2",
      domProps: { textContent: _vm._s(_setup.title) },
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "text-body-2 uiKitText--text mb-2",
      domProps: {
        textContent: _vm._s(
          `Вы можете дополнить отзыв ещё ${_setup.remainingCountOfSupplementsText}`
        ),
      },
    }),
    _vm._v(" "),
    _c("div", {
      staticClass:
        "text-body-2 primary--text link-decoration-underline cursor-pointer",
      domProps: { textContent: _vm._s("Посмотреть текущий отзыв") },
      on: { click: _setup.open },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }