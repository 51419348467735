var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-qa": "review_phone_confirmation" } },
    [
      _vm.userData.isPhoneConfirmedByCall
        ? _c(
            "VBtn",
            {
              staticClass: "mb-4",
              attrs: {
                block: "",
                depressed: "",
                color: "primary",
                "data-qa": "review_send_button",
              },
              on: { click: _vm.handleClickSendReview },
            },
            [
              _vm.isSomeImageSending
                ? _c("VProgressCircular", {
                    attrs: {
                      "data-qa": "review_send_button_progress",
                      color: "white",
                      size: "22",
                      width: "3",
                      indeterminate: "",
                    },
                  })
                : [_vm._v("\n      Отправить отзыв\n    ")],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("PolicyAndTerms"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }