import { render, staticRenderFns } from "./InfoListItem.vue?vue&type=template&id=48449f51&scoped=true&"
import script from "./InfoListItem.vue?vue&type=script&setup=true&lang=ts&"
export * from "./InfoListItem.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./InfoListItem.vue?vue&type=style&index=0&id=48449f51&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48449f51",
  null
  
)

export default component.exports