var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "text-body-2 uiKitTextSecondary--text text-center",
      attrs: { "data-qa": "review_send_policy" },
    },
    [
      _vm._v("\n  Отправляя отзыв, вы даёте согласие на\n  "),
      _c(
        "a",
        {
          staticClass: "primary--text link-decoration-none",
          attrs: {
            target: "_blank",
            rel: "noopener noreferrer",
            href: "/info/privacy-policy/",
          },
        },
        [_vm._v("обработку персональных данных")]
      ),
      _vm._v(
        _vm._s(
          _setup.reviewPageDataStore.commonData.isRussiaDomain ? "," : " и"
        ) + "\n  принимаете\n  "
      ),
      _c(
        "a",
        {
          staticClass: "primary--text link-decoration-none",
          attrs: {
            target: "_blank",
            rel: "noopener noreferrer",
            href: "/info/terms-of-use/",
          },
        },
        [_vm._v("пользовательское соглашение")]
      ),
      _vm._v(
        _vm._s(
          _setup.reviewPageDataStore.commonData.isRussiaDomain ? " и" : ","
        ) + "\n  "
      ),
      _setup.reviewPageDataStore.commonData.isRussiaDomain
        ? [
            _setup.reviewFormDataStore.isSelectedLpuAddressInPrs
              ? [
                  _vm._v("\n      условия программ\n      "),
                  _c(
                    "a",
                    {
                      staticClass: "primary--text link-decoration-none",
                      attrs: {
                        target: "_blank",
                        rel: "noopener noreferrer",
                        href: "/info/authors-protection/",
                      },
                    },
                    [_vm._v("«Защита авторов отзывов»")]
                  ),
                  _vm._v("\n      и\n      "),
                  _c(
                    "a",
                    {
                      staticClass: "primary--text link-decoration-none",
                      attrs: {
                        target: "_blank",
                        href: "/static/_v1/pd/documents/pdf/Регламент_Сила_отзыва_для_пациентов.pdf",
                      },
                    },
                    [_vm._v("«Сила отзыва»")]
                  ),
                  _vm._v(",\n    "),
                ]
              : [
                  _c(
                    "a",
                    {
                      staticClass: "primary--text link-decoration-none",
                      attrs: {
                        target: "_blank",
                        rel: "noopener noreferrer",
                        href: "/info/authors-protection/",
                      },
                    },
                    [_vm._v("условия программы защиты авторов отзывов")]
                  ),
                  _vm._v(",\n    "),
                ],
          ]
        : _vm._e(),
      _vm._v(
        "\n  а также подтверждаете получение согласия третьих лиц на публикацию их персональных данных\n"
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }