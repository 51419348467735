var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.SupplementBanner, {
        staticClass: "mb-4",
        attrs: {
          "remaining-count":
            _setup.supplementStepStore.remainingCountOfSupplements,
        },
      }),
      _vm._v(" "),
      _c(_setup.RateDialog, {
        attrs: {
          "now-date": _setup.reviewPageDataStore.nowDate,
          rate: _setup.supplementStepStore.rate,
        },
      }),
      _vm._v(" "),
      _c(_setup.SupplementForm, { ref: "supplementFormRef" }),
      _vm._v(" "),
      _c(_setup.AttachmentImages, {
        staticClass: "mb-4",
        attrs: { "has-document": "" },
      }),
      _vm._v(" "),
      _setup.userDataStore.userData.isPhoneConfirmedByCall
        ? _c(
            "VBtn",
            {
              staticClass: "mb-4",
              attrs: {
                block: "",
                depressed: "",
                color: "primary",
                "data-qa": "supplement_send_button",
              },
              on: { click: _setup.handleClickSendSupplement },
            },
            [
              _setup.isSomeImageSending
                ? _c("VProgressCircular", {
                    attrs: {
                      "data-qa": "supplement_send_button_progress",
                      color: "white",
                      size: "22",
                      width: "3",
                      indeterminate: "",
                    },
                  })
                : [_vm._v("\n      Отправить отзыв\n    ")],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(_setup.PolicyAndTerms),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }