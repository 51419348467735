import { axiosClient } from 'utils'
import getCSRFToken from 'utils/get/getCSRFToken'
import type { REVIEW_OBJECT_TYPES } from 'components/common/ReviewPage/constants'
import type { Rate } from 'components/common/ReviewPage/components/common/SupplementStep/types'

function sendSupplement({
  reviewObjectType,
  rateId,
  formData,
}: {
  reviewObjectType: typeof REVIEW_OBJECT_TYPES[keyof typeof REVIEW_OBJECT_TYPES],
  rateId: Rate['id'],
  formData: FormData,
}): Promise<{
  detail: string,
  medtochka_url: string,
}> {
  return axiosClient({
    method: 'POST',
    url: `/create/supplement/${reviewObjectType}/${rateId}/`,
    data: formData,
    headers: {
      'X-CSRFToken': getCSRFToken(),
    },
  })
    .then(({ data }) => data)
}

export default sendSupplement
