import {
  getLpuData,
  getDoctorData,
  getCommonData,
  getDraftData,
  getInitUserData,
  getReviewObjectTypes,
  getImagesOptions,
} from 'components/common/ReviewPage/interfaces'

/**
 * Входные данные главного компонента
 * */

const ReviewPageContextProps = {
  props: {
    lpuData: {
      type: Object,
      default: getLpuData,
    },
    doctorData: {
      type: Object,
      default: getDoctorData,
    },
    commonData: {
      type: Object,
      default: getCommonData,
    },
    draftData: {
      type: Object,
      default: getDraftData,
    },
    initUserData: {
      type: Object,
      default: getInitUserData,
    },
    reviewObjectTypes: {
      type: Object,
      default: getReviewObjectTypes,
    },
    reviewImagesOptions: {
      type: Object,
      default: getImagesOptions,
    },
    nowDateString: {
      type: String,
      default: '',
    },
    isSupplementPage: {
      type: Boolean,
      default: false,
    },
    supplementOptions: {
      type: Object,
      default: () => ({
        textMinLength: 0,
        textMaxLength: 0,
        remainingCount: 0,
        maxCount: 0,
      }),
    },
    existRate: {
      type: Object,
      default: () => ({
        id: 0,
        rating: {},
        supplements: [],
        createdDateString: '',
        avgAssessment: 0,
        assessments: [],
        comments: [],
      }),
    },
  },
}

export default ReviewPageContextProps
