var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("VExpandTransition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _setup.isVisibleAlertBanner,
                expression: "isVisibleAlertBanner",
              },
            ],
            ref: "alertBannerRef",
          },
          [
            _c(
              _setup.BaseAlert,
              { staticClass: "mb-4", attrs: { color: "error" } },
              [
                _c("div", { staticClass: "text-body-2" }, [
                  _vm._v(
                    "\n          Слишком короткое дополнение.\n          "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n          Не хватает символов: " +
                      _vm._s(_setup.needTextLength) +
                      "\n        "
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "v-form",
        {
          ref: "formRef",
          model: {
            value: _setup.isValidForm,
            callback: function ($$v) {
              _setup.isValidForm = $$v
            },
            expression: "isValidForm",
          },
        },
        [
          _c("v-textarea", {
            staticClass: "textarea",
            attrs: {
              label: "Дополнение к отзыву",
              rows: "5",
              placeholder:
                "Расскажите, почему обратились к врачу, как прошёл приём, помогло ли лечение",
              rules: _setup.supplementTextareaRules,
              error:
                _setup.supplementText.length >
                  _setup.supplementOptions.textMaxLength ||
                _setup.isVisibleAlertBanner,
              counter:
                _setup.supplementText.length >
                _setup.supplementOptions.textMaxLength,
              maxlength: _setup.supplementOptions.textMaxLength + 100,
              outlined: "",
              "auto-grow": "",
            },
            on: {
              blur: function ($event) {
                _setup.isBeenValidationAttempted = true
              },
            },
            model: {
              value: _setup.supplementText,
              callback: function ($$v) {
                _setup.supplementText = $$v
              },
              expression: "supplementText",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }