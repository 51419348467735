var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.hasDocumentInfo || _vm.hasPhotoInfo
    ? _c(
        "VExpansionPanels",
        { staticClass: "mb-4", attrs: { multiple: "" } },
        [
          _vm.hasDocumentInfo
            ? _c(
                "VExpansionPanel",
                [
                  _c(
                    "VExpansionPanelHeader",
                    {
                      staticClass: "p-4",
                      attrs: { "expand-icon": "ui-icon-arrow-down" },
                    },
                    [
                      _c("div", { staticClass: "mr-4" }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-1 uiKitText--text" },
                          [_vm._v("\n          Документы из клиники\n        ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "text-body-1 uiKitTextSecondary--text",
                          },
                          [
                            _vm._v(
                              "\n          подтвердят честность отзыва\n        "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "VExpansionPanelContent",
                    { staticClass: "mx-n2" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center text-body-2 uiKitText--text mb-3",
                        },
                        [
                          _c("span", {
                            staticClass:
                              "ui-icon-eye-close ui-icon-fz-medium mr-2",
                          }),
                          _vm._v("\n        Увидят только модераторы\n      "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "text-body-1 uiKitText--text mb-3" },
                        [
                          _vm._v(
                            "\n        Заключение, рецепт или другой документ с фамилией врача и печатью.\n        Имя пациента можно прикрыть.\n      "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _setup.reviewPageDataStore.commonData.isRussiaDomain
                        ? _c(
                            _setup.AlertInfo,
                            [
                              _c(
                                "span",
                                { staticClass: "text-body-2 uiKitText--text" },
                                [
                                  _vm._v(
                                    "\n          Если врач или клиника решат оспорить отзыв в суде,\n          мы защитим вас и возместим расходы.\n        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(_setup.ReviewProtectionProgram, {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "button",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "primary--text link-decoration-underline",
                                                  attrs: {
                                                    type: "button",
                                                    "data-qa":
                                                      "review_documents_button",
                                                  },
                                                },
                                                "button",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n              О программе защиты\n            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  447660799
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPhotoInfo
            ? _c(
                "VExpansionPanel",
                [
                  _c(
                    "VExpansionPanelHeader",
                    {
                      staticClass: "p-4",
                      attrs: { "expand-icon": "ui-icon-arrow-down" },
                    },
                    [
                      _c("div", { staticClass: "mr-4" }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-1 uiKitText--text" },
                          [_vm._v("\n          Фото\n        ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "text-body-1 uiKitTextSecondary--text",
                          },
                          [
                            _vm._v(
                              "\n          сделают отзыв интереснее\n        "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("VExpansionPanelContent", { staticClass: "mx-n2" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center text-body-2 uiKitText--text mb-3",
                      },
                      [
                        _c("span", {
                          staticClass:
                            "ui-icon-eye-open ui-icon-fz-medium mr-2",
                        }),
                        _vm._v("\n        Увидят все\n      "),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-body-1 uiKitText--text" }, [
                      _vm._v(
                        "\n        Результаты лечения, до и после — всё, что проиллюстрирует ваш отзыв.\n      "
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }