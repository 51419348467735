import type { RateCommentTypes } from 'components/common/ReviewPage/components/common/SupplementStep/types'

const COMMENT_TITLES: Record<RateCommentTypes, string> = {
  like: 'Понравилось',
  unlike: 'Не понравилось',
  story: 'Ваша история',
}

// Значения взяты из фильтра `comment_filter` (`pd/doctors/templatetags/custom_filter.py`)
const EMOJI_GIF_EQUIVALENTS = {
  ':)': '/static/_v1/pd/icons/emoji/face-smile.gif',
  ':(': '/static/_v1/pd/icons/emoji/face-sad.gif',
}

export {
  COMMENT_TITLES,
  EMOJI_GIF_EQUIVALENTS,
}
