<template>
  <div
    data-qa="rate_comments"
    class="uiKitText--text"
  >
    <div
      v-for="({ type, text }, commentIndex) in formattedComments"
      :key="commentIndex"
      :data-qa="`rate_comments_comment_${type}`"
      :class="{
        'mt-4': commentIndex > 0,
      }"
    >
      <div
        data-qa="rate_comments_comment_title"
        class="text-subtitle-2 mb-2"
        v-text="COMMENT_TITLES[type]"
      />
      <div
        data-qa="rate_comments_comment_text"
        class="text-body-1 text-pre-line text-break"
        v-html="text/* eslint-disable-line vue/no-v-html */"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import {
  COMMENT_TITLES,
  EMOJI_GIF_EQUIVALENTS,
} from 'components/common/ReviewPage/components/common/SupplementStep/components/RateDialog/components/RateComments/constants'
import useSupplementStepStore from 'components/common/ReviewPage/components/common/SupplementStep/stores/useSupplementStepStore'

const supplementStepStore = useSupplementStepStore()

const formattedComments = computed(() => supplementStepStore.rate.comments.map(comment => {
  let formattedText = comment.text

  Object.entries(EMOJI_GIF_EQUIVALENTS).forEach(([emoji, filePath]) => {
    formattedText = formattedText.replaceAll(emoji, `<img src=${filePath} alt="${emoji}">`)
  })

  return {
    ...comment,
    text: formattedText,
  }
}))
</script>
