<template>
  <div
    class="text-body-2 uiKitTextSecondary--text text-center"
    data-qa="review_send_policy"
  >
    Отправляя отзыв, вы даёте согласие на
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="/info/privacy-policy/"
      class="primary--text link-decoration-none"
    >обработку персональных данных</a>{{ reviewPageDataStore.commonData.isRussiaDomain ? ',' : ' и' }}
    принимаете
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="/info/terms-of-use/"
      class="primary--text link-decoration-none"
    >пользовательское соглашение</a>{{ reviewPageDataStore.commonData.isRussiaDomain ? ' и' : ',' }}
    <template
      v-if="reviewPageDataStore.commonData.isRussiaDomain"
    >
      <template v-if="reviewFormDataStore.isSelectedLpuAddressInPrs">
        условия программ
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="/info/authors-protection/"
          class="primary--text link-decoration-none"
        >«Защита авторов отзывов»</a>
        и
        <a
          target="_blank"
          href="/static/_v1/pd/documents/pdf/Регламент_Сила_отзыва_для_пациентов.pdf"
          class="primary--text link-decoration-none"
        >«Сила отзыва»</a>,
      </template>
      <template v-else>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="/info/authors-protection/"
          class="primary--text link-decoration-none"
        >условия программы защиты авторов отзывов</a>,
      </template>
    </template>
    а также подтверждаете получение согласия третьих лиц на&nbsp;публикацию их персональных данных
  </div>
</template>

<script lang="ts" setup>
import { useReviewFormDataStore, useReviewPageDataStore } from 'components/common/ReviewPage/stores'

const reviewPageDataStore = useReviewPageDataStore()
const reviewFormDataStore = useReviewFormDataStore()
</script>
