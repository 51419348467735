<template>
  <div>
    <VExpandTransition>
      <div
        v-show="isVisibleAlertBanner"
        ref="alertBannerRef"
      >
        <BaseAlert
          class="mb-4"
          color="error"
        >
          <div class="text-body-2">
            Слишком короткое дополнение.
            <br>
            Не хватает символов: {{ needTextLength }}
          </div>
        </BaseAlert>
      </div>
    </VExpandTransition>
    <v-form
      ref="formRef"
      v-model="isValidForm"
    >
      <v-textarea
        v-model="supplementText"
        label="Дополнение к отзыву"
        class="textarea"
        rows="5"
        placeholder="Расскажите, почему обратились к врачу, как прошёл приём, помогло ли лечение"
        :rules="supplementTextareaRules"
        :error="supplementText.length > supplementOptions.textMaxLength || isVisibleAlertBanner"
        :counter="supplementText.length > supplementOptions.textMaxLength"
        :maxlength="supplementOptions.textMaxLength + 100"
        outlined
        auto-grow
        @blur="isBeenValidationAttempted = true"
      />
    </v-form>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import useSupplementStepStore from 'components/common/ReviewPage/components/common/SupplementStep/stores/useSupplementStepStore'
import scrollToElement from 'utils/executors/scrollToElement'
// eslint-disable-next-line import/extensions
import BaseAlert from 'components/common/core/BaseAlert.vue'
import { storeToRefs } from 'pinia'

const supplementStepStore = useSupplementStepStore()
const {
  supplementText,
  supplementOptions,
} = storeToRefs(supplementStepStore)

const alertBannerRef = ref()
const formRef = ref()
const isValidForm = ref(false)
const isBeenValidationAttempted = ref(false)
const isVisibleAlertBanner = computed(() => (
  isBeenValidationAttempted.value
  && supplementText.value.length < supplementOptions.value.textMinLength
))
const supplementTextareaRules = [
  (v: string) => !/[A-ZА-ЯЁ]{6}/.test(v) || 'Пожалуйста, не пишите слова заглавными буквами',
  (v: string) => (
    v.length <= supplementOptions.value.textMaxLength
    || `Максимум ${supplementOptions.value.textMaxLength.toLocaleString('ru-RU')} символов`
  ),
  !isVisibleAlertBanner.value,
]
const needTextLength = computed(() => Math.max(
  supplementOptions.value.textMinLength - supplementText.value.length,
  0,
))

async function validate() {
  if (!formRef.value) {
    return false
  }

  isBeenValidationAttempted.value = true
  await formRef.value.validate()

  if (!isValidForm.value) {
    scrollToElement(alertBannerRef.value)
  }

  return isValidForm.value
}

defineExpose({
  validate,
})
</script>

<style lang="scss" scoped>
.textarea ::v-deep(textarea) {
  line-height: 125% !important;
}
</style>
