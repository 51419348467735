var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": _vm.dialogWidth,
        value: _vm.active,
        persistent: _vm.isPersistent,
      },
      on: {
        "click:outside": function ($event) {
          _vm.isPersistent ? "" : _vm.onClose()
        },
      },
    },
    [
      _c(
        "v-card",
        [
          _vm.hasTitle
            ? _c(
                "v-card-title",
                { attrs: { "data-qa": "title" } },
                [
                  _vm._t("title", function () {
                    return [
                      _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-text",
            {
              staticClass: "body-1 body-secondary--text",
              class: {
                "py-6": !_vm.hasTitle,
                "mt-1": _vm.hasTitle,
              },
              attrs: { "data-qa": "content" },
            },
            [
              _vm._t("content", function () {
                return [
                  _vm._v("\n        " + _vm._s(_vm.contentText) + "\n      "),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.closeText || _vm.confirmText
            ? _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.closeText
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: _vm.confirmText ? "uiKitText" : "primary",
                            "data-qa": "close_button",
                            text: "",
                          },
                          on: { click: _vm.onClose },
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.closeText) + "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.confirmText
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: _vm.confirmColor,
                            "data-qa": "confirm_button",
                            text: "",
                          },
                          on: { click: _vm.onConfirm },
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.confirmText) + "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }