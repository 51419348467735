var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPhoto || _vm.hasDocument
    ? _c(
        "div",
        { attrs: { "data-qa": "review_images" } },
        [
          _c(
            "div",
            {
              staticClass: "ui-text ui-text_subtitle-1 ui-kit-color-text mb-4",
              attrs: { "data-qa": "review_images _title" },
            },
            [_vm._v("\n    Прикрепите к отзыву\n  ")]
          ),
          _vm._v(" "),
          _c("DetailedInfoPanels", {
            attrs: {
              "has-photo-info": _vm.hasPhoto,
              "has-document-info": _vm.hasDocument,
            },
          }),
          _vm._v(" "),
          _c("FileUploader", {
            attrs: {
              "activator-text": _vm.activatorText,
              "activator-disabled-text": "Нельзя прикрепить больше 10 файлов",
              "picker-props": {
                allowedFileExtensions: _vm.allowedFileExtensions,
                fileValidationRules: _vm.fileValidationRules,
                imageValidationRules: _vm.imageValidationRules,
                loadFileError:
                  _vm.imagesErrors[_vm.FILE_ERROR_TYPES.uploadPhoto],
              },
              "max-attached-files": _vm.imagesOptions.maxAttachedImages,
              "upload-handler": _vm.requestDocumentRecognition,
              "preview-statuses-options": _vm.attachedImagesStatuses,
              "init-attached-files": _vm.attachedImages,
              "use-load-from-camera": _vm.isMobile,
              "min-count-preview-images-on-line": 3,
              "preview-image-max-width": 138,
              "preview-image-max-height": 138,
              "preview-image-width": "auto",
              "preview-image-height": "auto",
              "min-height": "120",
              "use-load-from-gallery": "",
              "is-top-picker": "",
            },
            on: {
              "file-uploader:add-file": _vm.addAttachedImage,
              "file-uploader:delete-file": _vm.deleteAttachedImage,
              "file-uploader:upload-pending": _vm.handleUploadPending,
              "file-uploader:upload-progress": _vm.handleUploadProgress,
              "file-uploader:upload-complete": _vm.handleUploadComplete,
              "file-uploader:upload-success": _vm.handleUploadSuccess,
              "file-uploader:upload-error": _vm.handleUploadError,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }