<template>
  <v-dialog
    v-model="isVisible"
    v-bind="$attrs"
    :width="fullscreen ? '100%' : MODAL_WIDTHS[modalType]"
    :fullscreen="fullscreen"
  >
    <div
      data-qa="base_dialog"
      class="base-dialog"
      :class="[
        `base-dialog_${modalType}`,
        {
          'h-100': fullscreen,
        },
      ]"
    >
      <div
        data-qa="base_dialog_body"
        class="base-dialog__body"
        :class="{
          'rounded-sm elevation-24': !fullscreen,
          'h-100 w-100': fullscreen,
        }"
      >
        <div
          class="base-dialog__toolbar"
          :class="{
            'px-2': hasPrependBtn || $slots.prependBtn,
          }"
        >
          <div
            v-if="title || hasPrependBtn || $slots.prependBtn"
            data-qa="base_dialog_title"
            class="d-flex align-center"
          >
            <v-btn
              v-if="hasPrependBtn"
              data-qa="base_dialog_prepend_btn"
              color="uiKitText"
              class="ml-0 mr-8"
              height="auto"
              width="auto"
              :icon="true"
              text
              @click="handleClickPrependBtn"
            >
              <slot name="prependBtn">
                <v-icon
                  color="uiKitText"
                  size="24"
                >
                  ui-icon-close-not-a-circle
                </v-icon>
              </slot>
            </v-btn>

            <div class="mr-auto">
              <div
                v-if="title"
                class="text-h6 uiKitText--text"
                v-text="title"
              />
              <div
                v-if="subtitle"
                class="text-body-2 uiKitTextSecondary--text"
                v-text="subtitle"
              />
            </div>
          </div>
          <slot name="subheader" />
        </div>
        <v-divider
          v-if="hasDivider"
          data-qa="base_dialog_divider"
        />
        <div
          ref="content"
          data-qa="base_dialog_content"
          class="base-dialog__content uiKitTextSecondary--text text-body-1"
          :class="[{
            'pt-0': !hasDivider,
            'px-4 pb-4': fullscreen,
            'overflow-y-auto': isOverflowYAuto,
          }, contentClass]"
        >
          <slot>
            <div v-html="text/* eslint-disable-line vue/no-v-html */" />
          </slot>
        </div>
        <div
          v-if="closeBtn.text || confirmBtn.text"
          class="base-dialog__actions"
          :class="{ 'pa-2': fullscreen }"
        >
          <v-spacer v-if="!fullscreen" />
          <v-btn
            v-if="closeBtn.text"
            data-qa="base_dialog_close_btn"
            height="40"
            class="base-dialog__action-btn"
            :class="{
              'px-2': closeBtn.variant === 'text',
              'flex-fill': fullscreen,
            }"
            :text="closeBtn.variant === 'text'"
            :depressed="confirmBtnProps.variant === 'flat'"
            :color="closeBtnProps.color"
            @click="handleClickClose"
          >
            {{ closeBtnProps.text }}
          </v-btn>
          <v-btn
            v-if="confirmBtn.text"
            data-qa="base_dialog_confirm_btn"
            height="40"
            class="base-dialog__action-btn"
            :class="{
              'px-2': confirmBtn.variant === 'text',
              'flex-fill': fullscreen,
            }"
            :text="confirmBtn.variant === 'text'"
            :depressed="confirmBtnProps.variant === 'flat'"
            :color="confirmBtn.color"
            @click="handleClickConfirm"
          >
            {{ confirmBtn.text }}
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import type { VBtn } from 'vuetify/lib'
import { MODAL_TYPES, MODAL_WIDTHS } from 'components/common/core/Base/BaseDialog/constants'
import type { ModalType } from 'components/common/core/Base/BaseDialog/types'

type Emits = {
  /* eslint-disable no-unused-vars */
  (e: 'base-dialog:click-prepend-btn'): void,
  (e: 'base-dialog:click-close'): void,
  (e: 'base-dialog:click-confirm'): void,
  (e: 'input', value: boolean): void,
  /* eslint-enable no-unused-vars */
}

const emit = defineEmits<Emits>()

const props = withDefaults(defineProps<{
  value: boolean,
  modalType?: ModalType,
  title?: string,
  subtitle?: string,
  text?: string,
  fullscreen?: boolean,
  hasDivider?: boolean,
  hasPrependBtn?: boolean,
  closeBtnProps?: any, // Заменить на VBtn['$props'] для vuetify-3
  confirmBtnProps?: any, // Заменить на VBtn['$props'] для vuetify-3
  isOverflowYAuto?: boolean,
  contentClass?: string,
}>(), {
  value: false,
  modalType: MODAL_TYPES.lg,
  title: '',
  text: '',
  subtitle: '',
  fullscreen: false,
  hasDivider: false,
  hasPrependBtn: false,
  closeBtnProps: () => ({}) as any,
  confirmBtnProps: () => ({}) as any,
  isOverflowYAuto: false,
  contentClass: '',
})

const isVisible = computed({
  get() {
    return props.value
  },
  set(value: boolean) {
    emit('input', value)
  },
})

const closeBtn = computed(() => ({
  text: '',
  variant: 'text',
  color: 'uiKitText',
  ...props.closeBtnProps,
} as any /* Заменить на VBtn['$props'] для vuetify-3 */))
const confirmBtn = computed(() => ({
  text: '',
  variant: 'text',
  color: 'primary',
  ...props.confirmBtnProps,
} as any /* Заменить на VBtn['$props'] для vuetify-3 */))

function handleClickPrependBtn() {
  emit('base-dialog:click-prepend-btn')
}

function handleClickClose() {
  emit('base-dialog:click-close')
}

function handleClickConfirm() {
  emit('base-dialog:click-confirm')
}

const content = ref()

defineExpose({
  content,
})
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.base-dialog {
  &__toolbar {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
  }

  &__body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: $ui-kit-bg-gray-0;
    flex: 1 1 auto;
  }

  &__content {
    position: relative;
    padding: 16px 24px 24px;
    flex: 1 1 auto;
  }

  &__actions {
    display: flex;
    align-items: center;
    padding: 8px 24px 24px;
  }

  &__actions > &__action-btn:not(:first-child):last-child {
    margin-left: 16px;
  }

  &_sm &__toolbar {
    padding-top: 24px;
  }

  &_sm,
  &_md {
    .base-dialog__actions {
      padding: 8px;
    }

    .base-dialog__actions > .base-dialog__action-btn:not(:first-child):last-child {
      margin-left: 8px;
    }
  }

  &_lg &__content {
    padding-top: 24px;
  }
}
</style>
