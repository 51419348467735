<template>
  <v-overlay
    class="side-menu"
    :value="value"
    :z-index="zIndex"
    @click="handleClickCloseOutside"
  >
    <v-sheet
      :width="sideMenuWidth"
      :class="sideMenuBodyClassList"
      height="100%"
      transition="slide-x-reverse-transition"
      light
    >
      <template v-if="!useAlternateContentTemplate">
        <div class="side-menu__header">
          <div class="side-menu__header-top">
            <v-icon
              v-if="!isBtnBack"
              class="side-menu__header-btn"
              color="uiKitText"
              size="24"
              @click="handleClickClose"
            >
              ui-icon-close-not-a-circle
            </v-icon>
            <v-icon
              v-else
              class="side-menu__header-btn"
              color="uiKitText"
              size="24"
              @click="handleClickBack"
            >
              ui-icon-arrow-back
            </v-icon>
            <div
              class="ui-text ui-text_h6 ui-kit-color-text ml-8 d-flex align-items-center"
              :class="{ 'ui-text_truncate': useLimitTitle }"
            >
              {{ title }}

              <slot name="titleIcon" />
            </div>
          </div>
          <div
            v-if="$slots['header-bottom']"
            class="side-menu__header-bottom"
          >
            <slot name="header-bottom" />
          </div>
        </div>

        <v-divider />

        <div :class="sideMenuContentClassList">
          <slot />
        </div>
      </template>
      <slot v-else />
    </v-sheet>
  </v-overlay>
</template>

<script>
export default {
  name: 'SideMenu',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isBtnBack: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    sideMenuWidth: {
      type: [String, Number],
      default: '400px',
      required: false,
    },
    sideMenuIsBlock: {
      type: Boolean,
      default: false,
    },
    sideMenuAutoScrollY: {
      type: Boolean,
      default: false,
    },
    useAlternateContentTemplate: {
      type: Boolean,
      default: false,
    },
    useLimitTitle: {
      type: Boolean,
      default: false,
    },
    contentClasses: {
      type: String,
      default: '',
    },
    zIndex: {
      type: Number,
      default: 200,
    },
  },
  computed: {
    sideMenuBodyClassList() {
      return `side-menu__body ${this.sideMenuIsBlock ? 'd-block' : 'd-flex flex-column'}`
    },
    sideMenuContentClassList() {
      let contentClassList = `side-menu__content ${this.contentClasses ? this.contentClasses : 'pa-6'}`

      if (this.sideMenuAutoScrollY) {
        contentClassList += ' side-menu__content_scrolled'
      }

      return contentClassList
    },
  },
  methods: {
    handleClickCloseOutside(event) {
      const isOverlay = event.target.classList.contains('v-overlay__scrim')

      if (!isOverlay) {
        return
      }

      this.handleClickClose(event)
    },
    handleClickClose(event) {
      this.$emit('side-menu:click-close', event)
    },
    handleClickBack() {
      this.$emit('side-menu:click-back')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.side-menu {
  align-items: stretch;
  justify-content: flex-end;

  &__body {
    @include elevation-12;

    background: $ui-kit-bg-gray-0;
  }

  &__header {
    padding: 0 8px;
  }

  &__header-top {
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    height: 56px;
  }

  &__header-btn {
    height: 24px;
    width: 24px;

    &:hover {
      background-color: transparent;
    }
  }

  &__content {
    display: flex;
    flex: 1 1 0;
    flex-flow: column;
    min-height: 0;
  }

  &__content_scrolled {
    overflow-y: auto;
    height: calc(100vh - 56px);
  }
}
</style>
