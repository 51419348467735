<template>
  <UserAuthorization
    ref="user-authorization"
    is-render-in-modal
    :is-phone-readonly="!!userData.phone"
    :is-visible="!userData.isLogged || !userData.isPhoneConfirmedByCall"
    :init-phone="userData.phone"
    :is-authorization-by-call="commonData.useCallConfirmation"
    :is-needed-confirm-by-call="!userData.isPhoneConfirmedByCall"
    :is-country-authorization="isFFDbPreparationCisPhone"
    @authorization:confirmed="handlePhoneConfirmed"
    @authorization:just-confirmed="handlePhoneJustConfirmed"
  />
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { createDevNotice } from 'utils'
import { PAGE_NEWRATE_YANDEX_GOALS } from 'yandexGoals'
import { sendYaGoal } from 'components/common/ReviewPage/functions'
import { getUserEmail } from 'components/common/ReviewPage/api'
import { USER_ACTION_AFTER_AUTH } from 'components/common/ReviewPage/constants'
import UserAuthorization from 'components/common/UserAuthorization/UserAuthorization'
import {
  useUserDataStore,
  useReviewPageDataStore,
  useReviewFormDataStore,
} from 'components/common/ReviewPage/stores'

export default {
  name: 'ReviewAuthorizationModal',
  components: {
    UserAuthorization,
  },
  data: () => ({
    isFFDbPreparationCisPhone: window.FEATURE_FLAGS.dbPreparationCisPhone,
  }),
  computed: {
    ...mapState(useUserDataStore, [
      'userData',
      'userEmailFormRef',
      'userActionAfterAuthorization',
    ]),
    ...mapState(useReviewPageDataStore, [
      'commonData',
    ]),
    ...mapState(useReviewFormDataStore, [
      'submittingReviewData',
    ]),
  },
  mounted() {
    this.updateUserAuthorizationRef(this.$refs['user-authorization'])
  },
  methods: {
    ...mapActions(useUserDataStore, [
      'updateUserData',
      'updateUserIsLogged',
      'updateUserAuthorizationRef',
    ]),
    ...mapActions(useReviewFormDataStore, [
      'sendReviewFormData',
      'handleRequestCheckExistReview',
    ]),
    handlePhoneJustConfirmed() {
      sendYaGoal(PAGE_NEWRATE_YANDEX_GOALS.commonPhoneConfirmed)

      if (!this.userData.email) {
        this.setEmailToUserData()
      }

      if (!this.submittingReviewData.isSuccessSubmit) {
        this.handleRequestCheckExistReview()
      }
    },
    handlePhoneConfirmed(phone) {
      this.updateUserIsLogged(true)
      this.updateUserData({ phone, isPhoneConfirmedByCall: true })
      this.handleUserActionAfterAuth()
    },
    handleUserActionAfterAuth() {
      if (this.userActionAfterAuthorization === USER_ACTION_AFTER_AUTH.sendReview) {
        this.sendReviewFormData()
        return
      }

      if (this.userActionAfterAuthorization === USER_ACTION_AFTER_AUTH.sendEmail && this.userEmailFormRef) {
        this.userEmailFormRef.handleSubmitEmail()
        return
      }

      if (this.userActionAfterAuthorization === USER_ACTION_AFTER_AUTH.sendEmailCode && this.userEmailFormRef) {
        this.userEmailFormRef.handleInputCode()
      }
    },
    async setEmailToUserData() {
      try {
        const email = await getUserEmail()

        if (!email) {
          return
        }

        this.updateUserData({ email })
      } catch (e) {
        createDevNotice({
          module: 'ReviewPhoneConfirmation.vue',
          method: 'setEmailToUserData',
          description: `Не удалось получить userEmail, ${e}`,
        })
      }
    },
  },
}
</script>
