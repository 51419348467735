var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "index-list-item d-flex align-center" }, [
    _c(
      "div",
      {
        staticClass:
          "index-list-item__number align-self-start ui-text ui-text_h3 ui-kit-color-brand",
      },
      [_vm._v("\n    " + _vm._s(_vm.number) + "\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "ui-text ui-text_body-1 ui-kit-color-text ml-4" },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }