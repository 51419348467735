<template>
  <v-dialog
    :max-width="dialogWidth"
    :value="active"
    :persistent="isPersistent"
    @click:outside="isPersistent ? '' : onClose()"
  >
    <v-card>
      <v-card-title
        v-if="hasTitle"
        data-qa="title"
      >
        <slot name="title">
          {{ title }}
        </slot>
      </v-card-title>

      <v-card-text
        class="body-1 body-secondary--text"
        :class="{
          'py-6': !hasTitle,
          'mt-1': hasTitle,
        }"
        data-qa="content"
      >
        <slot name="content">
          {{ contentText }}
        </slot>
      </v-card-text>
      <v-card-actions
        v-if="closeText || confirmText"
      >
        <v-spacer />
        <v-btn
          v-if="closeText"
          :color="confirmText ? 'uiKitText' : 'primary'"
          data-qa="close_button"
          text
          @click="onClose"
        >
          {{ closeText }}
        </v-btn>
        <v-btn
          v-if="confirmText"
          :color="confirmColor"
          data-qa="confirm_button"
          text
          @click="onConfirm"
        >
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ALERT_BIG_WIDTH, ALERT_SMALL_WIDTH } from 'components/common/AlertDialog/constants'

export default {
  name: 'AlertDialog',
  props: {
    big: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
    closeText: {
      type: String,
      default: '',
    },
    contentText: {
      type: String,
      default: '',
    },
    isPersistent: {
      type: Boolean,
      default: false,
    },
    confirmColor: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    dialogWidth() {
      if (this.big) {
        return ALERT_BIG_WIDTH
      }

      return ALERT_SMALL_WIDTH
    },
    hasTitle() {
      return Boolean(this.title || this.$slots.title)
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    onConfirm() {
      this.$emit('confirm')
    },
  },
}
</script>
