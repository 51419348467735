var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-overlay",
    {
      staticClass: "side-menu",
      attrs: { value: _vm.value, "z-index": _vm.zIndex },
      on: { click: _vm.handleClickCloseOutside },
    },
    [
      _c(
        "v-sheet",
        {
          class: _vm.sideMenuBodyClassList,
          attrs: {
            width: _vm.sideMenuWidth,
            height: "100%",
            transition: "slide-x-reverse-transition",
            light: "",
          },
        },
        [
          !_vm.useAlternateContentTemplate
            ? [
                _c("div", { staticClass: "side-menu__header" }, [
                  _c(
                    "div",
                    { staticClass: "side-menu__header-top" },
                    [
                      !_vm.isBtnBack
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "side-menu__header-btn",
                              attrs: { color: "uiKitText", size: "24" },
                              on: { click: _vm.handleClickClose },
                            },
                            [
                              _vm._v(
                                "\n            ui-icon-close-not-a-circle\n          "
                              ),
                            ]
                          )
                        : _c(
                            "v-icon",
                            {
                              staticClass: "side-menu__header-btn",
                              attrs: { color: "uiKitText", size: "24" },
                              on: { click: _vm.handleClickBack },
                            },
                            [
                              _vm._v(
                                "\n            ui-icon-arrow-back\n          "
                              ),
                            ]
                          ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ui-text ui-text_h6 ui-kit-color-text ml-8 d-flex align-items-center",
                          class: { "ui-text_truncate": _vm.useLimitTitle },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.title) +
                              "\n\n            "
                          ),
                          _vm._t("titleIcon"),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$slots["header-bottom"]
                    ? _c(
                        "div",
                        { staticClass: "side-menu__header-bottom" },
                        [_vm._t("header-bottom")],
                        2
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("v-divider"),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.sideMenuContentClassList },
                  [_vm._t("default")],
                  2
                ),
              ]
            : _vm._t("default"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }